import {useState,useEffect,useRef,useNavigate,decryptData,axios,handleApiError,DatePicker,CalendarIcon,Delete,Select,CategoryDropdown,CustomModal,SuccessfullVector,InfoVector,UploadBadgeIcon} from "../UserImport";
import "react-datepicker/dist/react-datepicker.css";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    margin: "auto",
    maxHeight: "13rem",
    overflow: "auto",
  }),
  menuList: (base) => ({
    ...base,
    overflowY: "scroll",
    maxHeight: "13rem",
    "::-webkit-scrollbar": {
      width: "0.5rem",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#d9d9d9",
      border: "1px solid rgba(255, 255, 255, 0.1475)",
      borderRadius: "0.875rem",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#bfbfbf",
    },
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "lightGrey" : "white",
    color: state.isSelected ? "black" : "black",
  }),
};

function CustomInput({ value, onClick }) {
  return (
    <div className="row addTask-date-filter m-0" onClick={onClick}>
      <input placeholder="Select Date" type="text" value={value} className="col-11 addTask-date-filter-input m-0" readOnly />
      <span className="col-1 p-0">
        <CalendarIcon />
      </span>
    </div>
  );
}
const formatDateToString = (date) => {
  if (date != null && date != "") {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return date;
};
export const AddCertificationModal = (props) => {
  const { version, setVersion } = props;
  const [certificateDropdown, setCertificateDropdown] = useState({
    certificateLists: [],
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [certificate, setCertificate] = useState({
    certificateId: null,
    certificate: null,
    category: null,
    subCategory: null,
    hasExpiryDate: false,
    certificateLink: "",
    certificateIssuedDate: "",
    certificateExpiryDate: null,
    uploadType: "Certificate",
    certificationLinkError :false
  });
  const [badge, setBadge] = useState({
    badgeId: null,
    badge: null,
    category: null,
    subCategory: null,
    hasExpiryDate: false,
    badgeIssuedDate: "",
    badgeExpiryDate: null,
    selectedFile: null,
    fileError : false
  });
  const fileInputRef = useRef([]);
  const navigate = useNavigate();
  const onBadgeChange = (selectedOption) => {
    if (selectedOption == null || selectedOption == undefined) {
      setBadge((prevData) => ({
        ...prevData,
        badge: null,
        category: null,
        subCategory: null,
      }));
    }
    if (selectedOption != null) {
      const selectedBadge = certificateDropdown?.certificateLists?.find((badge) => badge?.id === selectedOption?.id);
      const categoryId = selectedBadge ? selectedBadge.categoryId : null;
      const categoryName = selectedBadge ? selectedBadge.categoryName : null;
      const subCategoryId = selectedBadge ? selectedBadge.subCategoryId : null;
      const subCategoryName = selectedBadge ? selectedBadge.subCategoryName : null;
      setBadge((prevData) => ({
        ...prevData,
        badgeId: selectedOption.id,
        badge: selectedOption,
        category: {
          value: categoryName,
          label: categoryName,
          id: categoryId,
        },
        subCategory: {
          value: subCategoryName,
          label: subCategoryName,
          id: subCategoryId,
        },
      }));
    }
  };
  const onCertificateChange = (selectedOption) => {
    if (selectedOption == null || selectedOption == undefined) {
      setCertificate((prevData) => ({
        ...prevData,
        certificate: null,
        category: null,
        subCategory: null,
      }));
    }
    if (selectedOption != null) {
      const selectedInstructor = certificateDropdown?.certificateLists?.find((instructor) => instructor?.id === selectedOption?.id);
      const categoryId = selectedInstructor ? selectedInstructor.categoryId : null;
      const categoryName = selectedInstructor ? selectedInstructor.categoryName : null;
      const subCategoryId = selectedInstructor ? selectedInstructor.subCategoryId : null;
      const subCategoryName = selectedInstructor ? selectedInstructor.subCategoryName : null;
      setCertificate((prevData) => ({
        ...prevData,
        certificateId: selectedOption.id,
        certificate: selectedOption,
        category: {
          value: categoryName,
          label: categoryName,
          id: categoryId,
        },
        subCategory: {
          value: subCategoryName,
          label: subCategoryName,
          id: subCategoryId,
        },
      }));
    }
  };
  const handleOptionChange = (event) => {
    const selectedGrade = event.target.value;
    if (selectedGrade === "Certificate") {
      setCertificate((prevData) => ({
        ...prevData,
        uploadType: "Certificate",
      }));
    } else if (selectedGrade === "Badge") {
      setCertificate((prevData) => ({
        ...prevData,
        uploadType: "Badge",
      }));
    }
  };

  const areAllFieldsFilled = () => {
    let allFieldValid = false;
    if(!certificate.hasExpiryDate){
      if (certificate.uploadType === "Certificate") {
        allFieldValid = certificate.certificateIssuedDate && certificate.certificateLink && certificate.certificate !== null && !certificate.certificationLinkError;
      } else if (certificate.uploadType === "Badge") {
        allFieldValid = badge.badge && badge.badgeIssuedDate && badge.selectedFile != null;
      }
    }
    else{
      if (certificate.uploadType === "Certificate") {
        allFieldValid = certificate.certificateIssuedDate && certificate.certificateExpiryDate && certificate.certificateLink && certificate.certificate !== null && !certificate.certificationLinkError;
      } else if (certificate.uploadType === "Badge") {
        allFieldValid = badge.badge && badge.badgeIssuedDate && badge.badgeExpiryDate && badge.selectedFile != null;
      }
    }
    return allFieldValid;
  };
  const handleClickClear = () => {
    setCertificate((prevState) => ({
      ...prevState,
      certificate: null,
      category: null,
      subCategory: null,
      hasExpiryDate: false,
      certificateLink: "",
      certificateIssuedDate: "",
      certificateExpiryDate: null,
    }));
    setBadge((prevState) => ({
      ...prevState,
      badge: null,
      category: null,
      subCategory: null,
      hasExpiryDate: false,
      badgeIssuedDate: "",
      badgeExpiryDate: "",
      selectedFile: null,
    }));
    setCertificateDropdown((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const fetchUserList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Milestone/getAllMileStoneLists`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        const certificateList = response.data.result.map((certificate) => ({
          id: certificate.id,
          name: certificate.certificateName,
          categoryId: certificate.categoryId,
          categoryName: certificate.categoryName,
          subCategoryId: certificate.subCategoryId,
          subCategoryName: certificate.subCategoryName,
          milestoneTypeId: certificate.typeId,
          milestoneType: certificate.typeName,
        }));
        const sortedCertificateList = certificateList.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setCertificateDropdown((prevData) => ({
          ...prevData,
          certificateLists: sortedCertificateList,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  useEffect(() => {
    if (certificate.uploadType === "Certificate") {
      setBadge((prevState) => ({
        ...prevState,
        badge: null,
        category: null,
        subCategory: null,
        hasExpiryDate: false,
        badgeIssuedDate: "",
        badgeExpiryDate: "",
        selectedFile: null,
      }));
    } else {
      setCertificate((prevState) => ({
        ...prevState,
        certificate: null,
        category: null,
        subCategory: null,
        hasExpiryDate: false,
        certificateLink: "",
        certificateIssuedDate: "",
        certificateExpiryDate: null,
      }));
    }
  }, [certificate.uploadType]);

  useEffect(() => {
    if (!certificate.hasExpiryDate) {
      if (certificate.certificateExpiryDate !== "") {
        setCertificate((prevState) => ({
          ...prevState,
          certificateExpiryDate: null,
        }));
      }
    } else if (!badge.hasExpiryDate) {
      if (badge.badgeExpiryDate !== "") {
        setBadge((prevState) => ({
          ...prevState,
          badgeExpiryDate: "",
        }));
      }
    }
  }, [certificate.hasExpiryDate, badge.hasExpiryDate]);

  const handleCertificationLinkChange = (e) => {
    const link = e.target.value;
    setCertificate((prevState) => ({
      ...prevState,
      certificateLink: link,
    }));
    if (!link.match(/^(https?:\/\/|www\.)/)) {
      setCertificate((prevState) => ({
        ...prevState,
        certificationLinkError : true
      }));
    }
    else{
      setCertificate((prevState) => ({
        ...prevState,
        certificationLinkError : false
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
    var userId = parsedObject["decoded"]["Id"];
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/UserMileStone/add`;
    const formData = new FormData();
    formData.append(`userId`, userId);
    formData.append(`typeName`, certificate.uploadType);
    if (certificate.uploadType === "Badge") {
      let issuedDate = formatDateToString(badge.badgeIssuedDate);
      let expiryDate = badge.badgeExpiryDate !=null ? formatDateToString(badge.badgeExpiryDate) : badge.badgeExpiryDate;
      formData.append(`id`, badge.badgeId);
      formData.append(`issuedDate`, issuedDate);
      if(expiryDate!=null){
        formData.append(`expiryDate`, expiryDate);
      }
      formData.append(`badgeFile`, badge.selectedFile);
    } else {
      let issuedDate = formatDateToString(certificate.certificateIssuedDate);
      let expiryDate = certificate.certificateExpiryDate !=null ? formatDateToString(certificate.certificateExpiryDate) : certificate.certificateExpiryDate;
      formData.append(`id`, certificate.certificateId);
      formData.append(`issuedDate`, issuedDate);
      if(expiryDate!=null){
        formData.append(`expiryDate`, expiryDate);
      }
      formData.append(`link`, certificate.certificateLink);
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const response = await axios.post(url, formData, { headers });
    if (response.data.success === 200) {
      handleClickClear();
      setCertificateDropdown((prevState) => ({
        ...prevState,
        isModalOpen: true,
        modalType: "success",
        modalTitle: certificate.uploadType === "Certificate" ? "Certificate Added Successfully" : "Badge Added Successfully",
        modalMessage: certificate.uploadType === "Certificate" ? "New Certificate has been added successfully" : "New Badge has been added successfully",
        icon: <SuccessfullVector />,
      }));
      setVersion((prevState) => ({
        ...prevState,
        version: version + 1,
      }));
    } else {
      setCertificateDropdown((prevState) => ({
        ...prevState,
        isModalOpen: true,
        modalType: "info",
        modalTitle: certificate.uploadType === "Certificate" ? `Certificate Not Added` : "Badge Not Added",
        modalMessage: `${response.data.message}`,
        icon: <InfoVector />,
      }));
    }
  };

  const handleBrowseClick = () => {
    fileInputRef?.current.click();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    handleFileChange(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (file) => {
    let fileName = file[0];
    const allowedFileTypes = ["image/jpeg", "image/png","image/jpg"];
    if (!allowedFileTypes.includes(fileName.type)) {
      setBadge((prevState) => ({
        ...prevState,
        fileError: true,
      }));
      return true;
    }

    setBadge((prevData) => ({
      ...prevData,
      selectedFile: file[0],
    }));
  };
  const handleRemoveFile = () => {
    setBadge((prevData) => ({
      ...prevData,
      selectedFile: null,
      fileError : false
    }));
  };

  return (
    <div>
      <div className="modal fade" id="addCertModal" tabIndex="-1" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-1">
              <h5 className="modal-title modalheading-text" id="skillModalLabel">
                Add Certificates / Badges
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => handleClickClear(e)}></button>
            </div>

            <div className="modal-body">
              <form>
                <div className="mb-3 ">
                  <label className=" me-3">
                    <input
                      className="form-check-input color-of-radio me-1"
                      type="radio"
                      name="options"
                      id="Certificate"
                      value="Certificate"
                      checked={certificate.uploadType === "Certificate"}
                      onChange={handleOptionChange}
                    />
                    Certificate
                  </label>
                  <label>
                    <input
                      className="form-check-input color-of-radio me-1"
                      type="radio"
                      name="options"
                      id="Badge"
                      value="Badge"
                      checked={certificate.uploadType === "Badge"}
                      onChange={handleOptionChange}
                    />
                    Badge
                  </label>
                </div>

                {certificate.uploadType === "Badge" && (
                  <div className="mb-3">
                    <label htmlFor="instructor" className="additional-info-sub-heading mb-1">
                      Badge Name<span className="text-danger">*</span>
                    </label>
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      options={certificateDropdown?.certificateLists
                        ?.filter((option) => option.milestoneType === "Badge")
                        .map((option) => ({
                          value: option.name,
                          label: option.name,
                          id: option.id,
                        }))}
                      isSearchable={true}
                      onChange={(e) => onBadgeChange(e)}
                      value={badge.badge}
                      placeholder="Select Badge"
                    />
                  </div>
                )}
                {certificate.uploadType === "Certificate" && (
                  <div className="mb-3">
                    <label htmlFor="instructor" className="additional-info-sub-heading mb-1">
                      Certificate Name<span className="text-danger">*</span>
                    </label>
                    <Select
                      styles={customStyles}
                      isClearable={true}
                      options={certificateDropdown?.certificateLists
                        ?.filter((option) => option.milestoneType === "Certificate")
                        .map((option) => ({
                          value: option.name,
                          label: option.name,
                          id: option.id,
                        }))}
                      isSearchable={true}
                      onChange={(e) => onCertificateChange(e)}
                      value={certificate.certificate}
                      placeholder="Select Certificate"
                    />
                  </div>
                )}

                <CategoryDropdown
                  selectedCategory={certificate.uploadType === "Certificate" ? certificate : badge}
                  setSelectedCategory={certificate.uploadType === "Certificate" ? setCertificate : setBadge}
                  categoryLabel=""
                  subCategoryLabel=""
                  categoryEnabled={false}
                  subCategoryEnabled={false}
                  categoryFilter={null}
                  hideButtoms={true}
                />
                <div className="mb-3">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="start-date" className="col-form-label form-title-names">
                        {certificate.uploadType === "Badge" ? "Issued Date" : "Certificate Date"}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <DatePicker
                        maxDate = {new Date()}
                        selected={certificate.uploadType === "Certificate" ? certificate.certificateIssuedDate : badge.badgeIssuedDate}
                        popperPlacement="top-end"
                        onChange={(date) => {
                          if (certificate.uploadType === "Certificate") {
                            setCertificate((prevState) => ({
                              ...prevState,
                              certificateIssuedDate: date,
                              certificateExpiryDate : new Date(certificate.certificateExpiryDate) >= new Date(date) ? new Date(certificate.certificateExpiryDate) : "",
                            }));
                          } else {
                            setBadge((prevState) => ({
                              ...prevState,
                              badgeIssuedDate: date,
                              badgeExpiryDate : new Date(badge.badgeExpiryDate) >= new Date(date) ? new Date(badge.badgeExpiryDate) : "",
                            }));
                          }
                        }}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3 ">
                  <div className="form-check small checkbox gap-2 mb-2">
                    <input
                      className="form-check-input user-checkbox pointer"
                      type="checkbox"
                      id="hasExpiryDate"
                      checked={certificate.hasExpiryDate}
                      onChange={() =>
                        setCertificate((prevState) => ({
                          ...prevState,
                          hasExpiryDate: !certificate.hasExpiryDate,
                        }))
                      }
                    />
                    <label className="form-check-label pointer" htmlFor="hasExpiryDate">
                      Has Expiry Date ?
                    </label>
                  </div>
                </div>
                {certificate.hasExpiryDate && (
                  <div className="mb-3">
                    <div className="row">
                      <div className="col">
                        <label htmlFor="start-date" className="col-form-label form-title-names">
                          Expiry Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <DatePicker
                          minDate={certificate.uploadType === "Certificate" ? certificate.certificateIssuedDate != ""? certificate.certificateIssuedDate : "": badge.badgeIssuedDate!="" ? badge.badgeIssuedDate : ""}
                          selected={certificate.uploadType === "Certificate" ? certificate.certificateExpiryDate : badge.badgeExpiryDate}
                          popperPlacement="top-end"
                          onChange={(date) => {
                            if (certificate.uploadType === "Certificate") {
                              setCertificate((prevState) => ({
                                ...prevState,
                                certificateExpiryDate: date,
                              }));
                            } else {
                              setBadge((prevState) => ({
                                ...prevState,
                                badgeExpiryDate: date,
                              }));
                            }
                          }}
                          dateFormat="MM-dd-yyyy"
                          customInput={<CustomInput />}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {certificate.uploadType === "Certificate" && (
                  <div className="mb-3">
                    <label htmlFor="task-title" className="col-form-label form-title-names">
                      Certification Link<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="task-title"
                      placeholder="Enter Certification Link"
                      value={certificate.certificateLink}
                      // onChange={(e) => {
                      //   setCertificate((prevState) => ({
                      //     ...prevState,
                      //     certificateLink: e.target.value,
                      //   }));
                      // }}
                      onChange={handleCertificationLinkChange}
                    />
                    {certificate.certificationLinkError && <span style={{ color: "red", fontSize: "11px" }}>Invalid project link. Please enter a valid URL starting with http:// or https://.</span>}
                  </div>
                )}
                {certificate.uploadType === "Badge" && badge.selectedFile === null && (
                  <div>
                     <label htmlFor="task-title" className="col-form-label form-title-names badge-file">
                      Badge File<span className=""style={{ color: "red" }}>*</span>
                    </label>
                    <div className="mb-1 upload-badge-file" onDrop={(e) => handleDrop(e)} onDragOver={(e) => handleDragOver(e)}>
                      <div className="d-flex">
                        <div>
                          <input className="d-none" type="file" ref={fileInputRef} onChange={(e) => handleFileChange(e.target.files)} accept="image/*" />
                          <button type="button" onClick={handleBrowseClick} className="badge-file-upload-button">
                            <UploadBadgeIcon className="me-2"/>Upload File
                          </button>
                        </div>
                        <span className="ms-2 drag-drop-badge-text d-flex justify-content-center fw-bold">or Drop File</span>
                      </div>
                    </div>
                    <p className="mb-1 thumbnail-text">(Only *jpeg,*jpg,*png file)</p>
                    {badge.fileError && <span style={{ color: "red", fontSize: "12px" }}>Please select jpeg,jpg,png files only</span>}
                  </div>
                )}
                {badge.selectedFile && (
                  <div className="d-flex align-items-center ps-1 ast-search-wrapper">
                    <div className="progress-indicator-status">
                      <div className="d-flex align-items-center selected-module-files">
                        <div className="mx-1" data-toggle="tooltip" data-placement="top" title={badge.selectedFile.name}>{badge.selectedFile.name.length > 40 ? `${badge.selectedFile.name.substring(0, 40)}...` : badge.selectedFile.name}</div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        handleRemoveFile();
                      }}
                    >
                      <Delete className="badge-delete-icon" />
                    </div>
                  </div>
                )}
              </form>
            </div>

            <div className="modal-footer border-top-0">
              <button type="button" className="btn cancel-button" data-bs-dismiss="modal" onClick={(e) => handleClickClear(e)}>
                <span className="cancel-text">Cancel</span>
              </button>

              <button type="button" className="btn btn-primary save-button" data-bs-dismiss="modal" data-bs-target="#addCertModal" onClick={(e) => handleSubmit(e)} disabled={!areAllFieldsFilled()}>
                <span className="save-text-field">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {certificateDropdown.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: certificateDropdown.icon,
            show: certificateDropdown.isModalOpen,
            type: certificateDropdown.modalType,
            title: certificateDropdown.modalTitle,
            message: certificateDropdown.modalMessage,
            onCancel: handleClickClear,
          }}
        />
      )}
    </div>
  );
};

export default AddCertificationModal;
