import "./AdditionalInfo.css";
import { Select, Modal, useEffect, axios, decryptData, useNavigate, useState, handleApiError, ColonImage, AdditionalInfoClockIcon, AdditionalInfoAssignmentIcon, AdditionalInfoPptIcon, AdditionalInfoEbookIcon, AdditionalInfoVideoIcon, SizeLimit, AutoArchive } from "../UploadCoursesImport";

const AdditionalInfo = (props) => {
  const { uploadData, setUploadData, type } = props;
  const [instructors, setInstructors] = useState([]);

  const navigate = useNavigate();
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: "100%",
    }),

    menu: (provided) => ({
      ...provided,
      width: "100%",
      margin: "auto",
      maxHeight: "13rem",
      overflow: "auto",
    }),
    menuList: (base) => ({
      ...base,
      overflowY: "scroll",
      maxHeight: "13rem",
      "::-webkit-scrollbar": {
        width: "0.5rem",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#d9d9d9",
        border: "1px solid rgba(255, 255, 255, 0.1475)",
        borderRadius: "0.875rem",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#bfbfbf",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "lightGrey" : "white",
      color: state.isSelected ? "black" : "black",
    }),
  };
  const onInstructorChange = (e) => {
    setUploadData((prevData) => ({
      ...prevData,
      instructor: e?.value,
      instructorId: e?.id,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;
    switch (name) {
      case 'courseHrs':
        if (value < 0 || value > 23) {
          validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 23);
        }
        break;
      case 'courseMin':
        if (value < 0 || value > 59) {
          validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 59);
        }
        break;
      default:
        break;
    }
    setUploadData({ ...uploadData, [name]: validatedValue });
  };

  const handleKeyDown = (event) => {
    if (event.key === "." || event.key === "-" || event.key.toLowerCase() === "e") {
      event.preventDefault();
    }
  };

  const handleOptionChange = (event) => {
    const selectedGrade = event.target.value;
    if (selectedGrade === "autoGraded") {
      setUploadData((prevData) => ({
        ...prevData,
        courseGrade: process.env.REACT_APP_COURSE_GRADE_AUTO_GRADED,
      }));
    } else if (selectedGrade === "instructorGraded") {
      setUploadData((prevData) => ({
        ...prevData,
        courseGrade: process.env.REACT_APP_COURSE_GRADE_INSTRUCTOR_GRADED,
      }));
    }
  };

  const fetchUserList = async () => {
    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

    const url = `${process.env.REACT_APP_API_URL_DOTNET}/User/getAllUserByRole`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = { roleName: process.env.REACT_APP_USER_DES_MENTOR };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        const modifiedUsers = response.data.result.map((user) => ({
          id: user.id,
          name: `${user?.firstName || ""} ${user?.lastName || ""}`,
        }));
        const sortedUsers = modifiedUsers.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setInstructors(sortedUsers);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  const handleAutoArchiveChange = () => {
    setUploadData((prevData) => ({
      ...prevData,
      autoArchive: !prevData.autoArchive,
    }));

    if (!uploadData.autoArchive) {
      const modal = new Modal(document.getElementById("archiveModal"));
      modal.show();
    }
  };
  return (
    <form className="container">
      <div className="mb-3">
        <label htmlFor="instructor" className="additional-info-sub-heading mb-1">
          Instructor<span className="text-danger">*</span>
        </label>
        <Select
          styles={customStyles}
          isClearable={true}
          options={instructors?.map((option) => ({
            value: option.name,
            label: option.name,
            id: option.id,
          }))}
          isSearchable={false}
          onChange={(e) => onInstructorChange(e)}
          value={
            uploadData.instructor
              ? {
                value: uploadData.instructor,
                label: uploadData.instructor,
              }
              : null
          }
          placeholder="Select Instructor"
        />
      </div>
      {type == "course" && (
        <div style={{ marginBottom: "2rem" }}>
          <div>
            <label className="additional-info-sub-heading mb-1">
              Course Grade<span className="text-danger">*</span>
            </label>
          </div>
          <div className="form-check-inline">
            <input type="radio" className="form-check-input" id="autoGraded" name="gradingType" value="autoGraded" onChange={handleOptionChange} checked={uploadData.courseGrade === process.env.REACT_APP_COURSE_GRADE_AUTO_GRADED} />
            <label className="form-check-label mx-1 additional-info-sub-heading" htmlFor="autoGraded">
              Auto-graded
            </label>
          </div>
          <div className="form-check-inline">
            <input type="radio" className="form-check-input" id="instructorGraded" name="gradingType" value="instructorGraded" onChange={handleOptionChange} checked={uploadData.courseGrade === process.env.REACT_APP_COURSE_GRADE_INSTRUCTOR_GRADED} />
            <label className="form-check-label mx-1 additional-info-sub-heading" htmlFor="instructorGraded">
              Instructor graded
            </label>
          </div>
        </div>
      )}
      <div className="mb-3 d-flex align-items-center">
        <label className="additional-info-sub-heading">Auto Archive</label>
        <div className="d-flex align-items-center">
          <div className="ms-2 form-check form-switch mb-0">
            <input
              type="checkbox"
              className="form-check-input"
              id="autoArchiveSwitch"
              checked={uploadData.autoArchive}
              onChange={handleAutoArchiveChange}
            />
            <label className="form-check-label" htmlFor="autoArchiveSwitch"></label>
          </div>
        </div>
      </div>
      <div className="modal fade" id="archiveModal" tabindex="-1" aria-labelledby="archiveModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  setUploadData((prevData) => ({
                    ...prevData,
                    autoArchive: !prevData.autoArchive,
                  }))
                }
              ></button>
            </div>
            <div className="modal-body p-0">
              <div className="modal-body d-flex justify-content-center p-0">
                <AutoArchive />
              </div>
              <div className="modal-body d-flex justify-content-center pb-0">
                <h5 className="archive-head">Auto Archive</h5>
              </div>
              <div className="modal-body d-flex justify-content-center pt-0 pb-0">
                <p className="archive-text">The {type} will be automatically archived if no one uses it for a period of three months.</p>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center gap-2 border-top-0">
              <button
                type="button"
                className="auto-archive-no-btn me-1"
                data-bs-dismiss="modal"
                onClick={() =>
                  setUploadData((prevData) => ({
                    ...prevData,
                    autoArchive: !prevData.autoArchive,
                  }))
                }
              >
                No, Cancel
              </button>
              <button type="button" className="auto-archive-yes-btn ms-1" data-bs-dismiss="modal" onClick={() => {
                setUploadData((prevData) => ({
                  ...prevData,
                  autoArchiveModal: true,
                }))
              }}>
                Yes, Proceed
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row-12 course-duration-box">
        <div className="row-12 mb-3">
          <h3 className="define-course-duration">
            Define {type} Duration<span className="text-danger">*</span>
          </h3>
        </div>
        <div className="mb-3">
          <div className="file-size-limit gap-2">
            <SizeLimit />
            <p className="limit-text">Use the below information to calculate estimated  duration based on the total contents.</p>
          </div>
        </div>

        {/* <div className="row-12 mb-3">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                  <AdditionalInfoEbookIcon />
                  <div className="mx-1">
                    <label htmlFor="ebook">E-Book / PDF (in Pages)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col pe-0">
                    <input disabled type="number" min="0" className="form-control completion-time-input-pdf" placeholder="Total Pdfs" name="totalPdf" value={uploadData.totalPdf} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                  <div className="col p-0">
                    <input
                      disabled={uploadData.totalPdf === "0" || uploadData.totalPdf === 0}
                      type="number"
                      min="0"
                      pattern="[0-9]+"
                      className="form-control completion-time-input-pdf"
                      placeholder="Total Pages"
                      name="pdfPages"
                      value={uploadData.pdfPages}
                      onKeyDown={(event) => handleKeyDown(event)}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                  <AdditionalInfoPptIcon />
                  <div className="mx-1">
                    <label htmlFor="ppt">PPT (in Pages)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col pe-0">
                    <input disabled type="number" min="0" className="form-control completion-time-input-pdf" placeholder="Total PPTs" name="totalPPT" value={uploadData.totalPPT} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                  <div className="col p-0">
                    <input disabled={uploadData.totalPPT === "0" || uploadData.totalPPT === 0} type="number" min="0" className="form-control completion-time-input-pdf" placeholder="Total Pages" name="PPTPages" value={uploadData.PPTPages} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-12 mb-3">
          <div className="col-12">
            <div className="row">
              {type == "course" && (
                <>
                  <div className="col-3">
                    <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                      <AdditionalInfoAssignmentIcon />
                      <div className="mx-1">
                        <label htmlFor="assignment ">Quiz Questions </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <input disabled type="number" min="0" className="form-control completion-time-input-assignments" placeholder="No. of Assignments" name="totalAssignments" value={uploadData.quizQuestions} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                      <AdditionalInfoAssignmentIcon />
                      <div className="mx-1">
                        <label htmlFor="assignment ">Assignment </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <input disabled type="number" min="0" className="form-control completion-time-input-assignments" placeholder="No. of Assignments" name="totalAssignments" value={uploadData.totalAssignments} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
              <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                <AdditionalInfoClockIcon />
                <div className="mx-1">
                  <label htmlFor="completiontime ">Course Completion Time </label>
                </div>
              </div>
              <div className="row">
                <div className="col-6 ps-1 pe-1">
                  <input type="number" className="form-control completion-time-input" placeholder="hr(s)" name="courseHrs" value={uploadData.courseHrs} disabled/>
                </div>
                <div className="col-1 d-flex align-items-center">
                  <ColonImage />
                </div>
                <div className="col-5 ps-1 pe-1">
                  <input type="number" className="form-control completion-time-input" placeholder="min(s)" name="courseMin" value={uploadData.courseMin} disabled/>
                </div>
              </div>
            </div>
                </>
              )}
            </div>
          </div>
        </div> */}

        <div className="row-12 mb-3">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                  <AdditionalInfoVideoIcon />
                  <div className="mx-1">
                    <label htmlFor="totalvideo">Total Videos</label>
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className= "col-12 pe-1">
                    <input disabled type="number" min="0" className="form-control completion-time-input"
                      placeholder="Videos" name="totalVideos" value={uploadData.totalVideos}
                      onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                  {/* {type!="course" && (
                    <>
                    <div className="col-3 ps- pe-0">
                      <input disabled={uploadData.totalVideos === 0} type="number" min="0" className="form-control completion-time-input" placeholder="hrs" name="videoHrs" value={uploadData.videoHrs} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                    </div>
                    <div className="col-1 d-flex align-items-center justify-content-center">
                      <ColonImage />
                    </div>
                    <div className="col-3 ps-0">
                      <input disabled={uploadData.totalVideos === 0} type="number" min="0" className="form-control completion-time-input" placeholder="min" name="videoMins" value={uploadData.videoMins} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                    </div>
                    </>
                )} */}
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                  <AdditionalInfoEbookIcon />
                  <div className="mx-1">
                    <label htmlFor="ebook">E-Book / PDF (in Pages)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col pe-0">
                    <input disabled type="number" min="0" className="form-control completion-time-input-pdf" placeholder="Total Pdfs" name="totalPdf" value={uploadData.totalPdf} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                  <div className="col p-0">
                    <input
                      disabled={uploadData.totalPdf === "0" || uploadData.totalPdf === 0}
                      type="number"
                      min="0"
                      pattern="[0-9]+"
                      className="form-control completion-time-input-pdf"
                      placeholder="Total Pages"
                      name="pdfPages"
                      value={uploadData.pdfPages}
                      onKeyDown={(event) => handleKeyDown(event)}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-12 mb-3">
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                  <AdditionalInfoPptIcon />
                  <div className="mx-1">
                    <label htmlFor="ppt">PPT (in Pages)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col pe-0">
                    <input disabled type="number" min="0" className="form-control completion-time-input-pdf" placeholder="Total PPTs" name="totalPPT" value={uploadData.totalPPT} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                  <div className="col p-0">
                    <input disabled={uploadData.totalPPT === "0" || uploadData.totalPPT === 0} type="number" min="0" className="form-control completion-time-input-pdf" placeholder="Total Pages" name="PPTPages" value={uploadData.PPTPages} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                  </div>
                </div>
              </div>
              {type == "course" && (
                <>
                  <div className="col-3">
                    <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                      <AdditionalInfoAssignmentIcon />
                      <div className="mx-1">
                        <label htmlFor="assignment ">Quiz Questions </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <input disabled type="number" min="0" className="form-control completion-time-input-assignments" placeholder="No. of Assignments" name="totalAssignments" value={uploadData.quizQuestions} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                      <AdditionalInfoAssignmentIcon />
                      <div className="mx-1">
                        <label htmlFor="assignment ">Assignment </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <input disabled type="number" min="0" className="form-control completion-time-input-assignments" placeholder="No. of Assignments" name="totalAssignments" value={uploadData.totalAssignments} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {type!="course" && (
                    <>
                      <div className="col-6">
                        <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                          <AdditionalInfoClockIcon />
                          <div className="mx-1">
                            <label htmlFor="ebook">Total Duration</label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col pe-0">
                            <input disabled={uploadData.totalVideos === 0} type="number" min="0" className="form-control completion-time-input" placeholder="hrs" name="videoHrs" value={uploadData.videoHrs} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                          </div>
                          <div className="col p-0">
                            <input disabled={uploadData.totalVideos === 0} type="number" min="0" className="form-control completion-time-input" placeholder="min" name="videoMins" value={uploadData.videoMins} onKeyDown={(event) => handleKeyDown(event)} onChange={(e) => handleInputChange(e)} />
                          </div>
                        </div>
                      </div>
                    </>
                )}
            </div>
          </div>
        </div>
        {type == "course" && (
          <div className="row-12">
            <div className="col-7">
              <div className="d-flex align-items-center additional-info-sub-heading mb-1">
                <AdditionalInfoClockIcon />
                <div className="mx-1">
                  <label htmlFor="completiontime ">Course Completion Time </label>
                </div>
              </div>
              <div className="row">
                <div className="col-3 ps-1 pe-1">
                  <input type="number" className="form-control completion-time-input" placeholder="hr(s)" name="courseHrs" value={uploadData.courseHrs} disabled/>
                </div>
                <div className="col-1 d-flex align-items-center">
                  <ColonImage />
                </div>
                <div className="col-3 ps-1 pe-1">
                  <input type="number" className="form-control completion-time-input" placeholder="min(s)" name="courseMin" value={uploadData.courseMin} disabled/>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default AdditionalInfo;