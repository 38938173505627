import { useState, useEffect, axios, handleApiError, decryptData, useNavigate, ChevronDown, ChevronUp, CourseCard, CourseCardSkeleton } from "../../UserImport";
import "./MostPopularCourses.css";

const MostPopularCourses = (props) => {
  const { enrolledCoursesData } = props;
  const [showAllCourses, setShowAllCourses] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  var userId = parsedObject["decoded"]["Id"];

  const getCourseData = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getAll`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200 || response.data.success === 204) {
        const filteredData = response.data.result.filter(item => item.isPublished && item.isActive);
        const sortedData = filteredData.sort((a, b) => b.userEnrolledCount - a.userEnrolledCount);
        setCourseData(sortedData);
        setIsLoading(false);
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const StartCourse = async (storeCourseId) => {
    var userId = parsedObject["decoded"]["Id"];
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/startCourse?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const formData = new FormData();
    formData.append("courseId", storeCourseId);
    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 201) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", storeCourseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const ResumeCourse = async (courseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/resumeCourse?userId=${userId}&courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", courseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const toggleShowCourses = () => {
    setShowAllCourses(!showAllCourses);
  };

  const displayedCourses = courseData.slice(0, 4);

  useEffect(() => {
    getCourseData();
  }, []);

  return (
    <div className="d-grid gap-1 p-0">
      <div className="d-flex justify-content-between">
        <div className="lms-dashboard-enrolled-course-heading">Most Popular Courses</div>
      </div>
      <div className={`d-grid gap-2 ${showAllCourses ? displayedCourses?.length === 3 ? "read-less-wrap-third" :"read-less-wrap" : displayedCourses?.length > 2 ? "read-more-wrap-height" : "read-more-wrap"}`}>
        {isLoading
          ? Array.from({ length: 2 }).map((_, index) => <CourseCardSkeleton key={index} />)
          : displayedCourses.map((course, index) => {
            const enrolledCourse = enrolledCoursesData?.find(enrolledCourse => enrolledCourse.id === course.id);
            let activityType;
            if (enrolledCourse) {
              activityType = enrolledCourse.courseStatus === "Enrolled" ? "Play" : enrolledCourse.courseStatus === "Completed" ? "Completed" : "Resume";
              return (
                <div className={`${index > 1 && !showAllCourses ? "read-more-target" : "read-less-target"}`}>
                <CourseCard
                  key={index}
                  courseCardProp={{
                    courseType: true,
                    activityType: activityType,
                    courseImageLink: enrolledCourse?.thumbnailUri,
                    activityLevel: enrolledCourse?.levelName,
                    activityGradeType: enrolledCourse?.gradeType,
                    activityInstructor: enrolledCourse?.instructorName,
                    activityName: enrolledCourse?.courseTitle,
                    activityDescription: enrolledCourse?.description,
                    activityCompleteCount: enrolledCourse?.completedModuleCount,
                    activityTotalCount: enrolledCourse?.moduleCount,
                    activityCourseStatus: enrolledCourse.courseStatus,
                    activityModuleCount: enrolledCourse?.moduleCount,
                    activityDuration: enrolledCourse?.duration,
                    activityStartDate: enrolledCourse?.startDate,
                    activityState: enrolledCourse?.courseState,
                    IsAssignmentReviewed: enrolledCourse?.isAssignmentReviewed,
                    IsAssignmentSubmitted: enrolledCourse?.isAssignmentSubmitted,
                    activityResume: () => ResumeCourse(enrolledCourse?.id),
                    activityPlay: () => StartCourse(enrolledCourse?.id),
                  }}
                />
                </div>
              );
            } else {
              return (
                <div className={`${index > 1 && !showAllCourses ? "read-more-target" : "read-less-target"}`}>
                  <CourseCard
                    key={index}
                    courseCardProp={{
                      courseType: true,
                      activityType: "Enrolled",
                      courseImageLink: course?.thumbnailUri,
                      activityLevel: course?.levelName,
                      activityGradeType: course?.gradeType,
                      activityInstructor:
                      course?.instructorList && course?.instructorList.length > 0 ? `${course?.instructorList[0]?.firstName || ""} ${course?.instructorList[0]?.lastName || ""}` : "",
                      activityEnrolledOrViews: course?.userEnrolledCount,
                      activityName: course?.title,
                      activityDescription: course?.description,
                      activityModuleCount: course?.modules?.length,
                      activityDuration: course?.courseCompletionTime,
                      activityStartDate: course?.createdDate,
                      courseId: course?.id
                    }}
                  />
                </div>
              );
            }
          })}
      </div>
      {!isLoading && displayedCourses?.length > 2 && <div className="d-flex justify-content-center align-items-center mt-3">
        <div className="d-flex align-items-center gap-2 pointer" onClick={toggleShowCourses}>
          <div className="m-0 lms-show-less-more">{showAllCourses ? "Show Less" : "Show More"}</div>
          <div>{showAllCourses ? <ChevronUp className="chevron-lms" /> : <ChevronDown className="chevron-lms" />}</div>
        </div>
      </div>}
    </div>
  );
};

export default MostPopularCourses;
