import { ContentIcon, Clock, CourseHistoryCalendarIcon, Dot, Link, EmptyCourseUpload, ArrowForwardIcon, TotalCourses, useNavigate, FormatDate, CourseUploadHistorySkeleton } from "../../AdminImport";

const CourseUploadHistory = ({ courseUploadData, isLoading }) => {
  const navigate = useNavigate();
  return (
    <>
      {isLoading ?
        (<>
          {Array.from({ length: 6 }).map((_, index) => (
            <CourseUploadHistorySkeleton />
          ))}{" "}
        </>) : courseUploadData?.length !== 0 ? (
          courseUploadData?.map((course) => (
            <div key={course.id} className="col-12 border-bottom">
              <div className="col-12 d-flex justify-content-between">
                <div
                  className="pointer"
                  onClick={() => {
                    navigate("/admin/lms/total-uploaded-courses");
                  }}
                >
                  <div className="col-12 d-flex gap-1">
                    <p className="mb-1 course-upload-heading-level">{course.levelName}</p>
                    <div className="mb-1 d-flex align-items-center">
                      <Dot />
                    </div>
                    <p className="mb-1 course-upload-heading-level">
                      {course.uploadedByFirstName} {course.uploadedByLastName}
                    </p>
                  </div>
                  <div className="col-12">
                    <p className="mb-2 course-upload-heading-name" data-toggle="tooltip" data-placement="top" title={course?.title}>
                      {course?.title.length > 50 ? `${course?.title.substring(0, 40)}...` : course?.title}
                    </p>
                  </div>
                </div>
                <div className="image-box">
                  <img src={course.thumbnailUri && course.thumbnailUri.includes("https://") ? course.thumbnailUri : TotalCourses} width={32} height={32} alt={course.title} />
                </div>
              </div>

              <div className="col-12 d-flex justify-content-between mb-3">
                <div className="course-content-details d-flex align-items-center gap-1 fw-bold">
                  <ContentIcon /> {course.modules.length} Modules
                </div>
                <div className="course-content-details d-flex align-items-center gap-1 fw-bold">
                  <Clock /> {course?.courseCompletionTime !== null && course?.courseCompletionTime?.replace(/(\d+) days (\d+) hrs (\d+) mins/, (_, d, h, m) => `${Number(d) * 24 + Number(h)} hr(s) ${m} min(s)`)}
                </div>
                <div className="course-content-details d-flex align-items-center gap-1 fw-bold">
                  <CourseHistoryCalendarIcon /> {FormatDate(course?.createdDate)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="course-upload-empty">
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <EmptyCourseUpload />
              <p className="fw-bold mt-3 mb-1 empty-task-text">No course has been uploaded yet!</p>
              <Link to={"/admin/lms/addCourse"} className="task-link">
                Upload New Course
                <ArrowForwardIcon className="ms-2" />
              </Link>
            </div>
          </div>
        )}
    </>
  );
};

export default CourseUploadHistory;
