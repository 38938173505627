import { useParams } from "react-router-dom";
import { useReducer, useLocation, useState, useEffect, Header, TechTalkInfo, FileUploadProgress, FileUploadFooter, SideNav, TechActionType, axios, decryptData, handleApiError, useNavigate, AdditionalInfo, BreadCrumbs, UploadTips, Draft, PublishPopUp, DraftComp, TechTalkVideo, CustomModal, SuccessfullVector, AlertInfo, useRef } from "../UploadCourses/UploadCoursesImport";
import "./TechTalk.css";

const initialState = {
  selectedTechIds: [],
  selectedTechNames: [],
  techValue: "",
};
const reducer = (state, action) => {
  switch (action.type) {
    case TechActionType.SET_TECH_VALUE:
      return { ...state, techValue: action.payload };
    case TechActionType.SET_TECH_ID:
      return { ...state, selectedTechIds: action.payload };
    case TechActionType.SET_TECH_NAME:
      return { ...state, selectedTechNames: action.payload };
    case TechActionType.RESET:
      return initialState;
    default:
      return state;
  }
};
const TechTalkState = {
  id: null,
  name: "",
  description: "",
  thumbnail: "",
  currPage: 1,
  category: null,
  subCategory: null,
  thumbnailFile: null,
  isThumbNailChanged: false,
  thumbnailOldUri: null,
  type: "Tech Talk",
  dropdown: false,
  selectedFiles: [],
  instructorId: 0,
  instructor: null,
  totalVideos: 0,
  totalPdf: "",
  pdfPages: "",
  totalPPT: "",
  PPTPages: "",
  autoArchive: false,
  isSavedClicked: false,
  videoHrs: null,
  videoMins: null,
  isPublish: false,
  isLoading: false,
  isNextAllowed: false,
  error: {
    name: false,
    description: false,
  }
};
const TechTalk = () => {
  const [uploadData, setUploadData] = useState(TechTalkState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isModalOpen, setIsModalOpen] = useState({
    popUp: false,
    draft: false,
    isLoading: false
  });
  const [isOpen, setIsOpen] = useState({
    popUp: false,
    isLoading: false,
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { status } = useParams();

  var parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  let techTalkId = sessionStorage.getItem("techTalkId");

  const handlePublish = async (isPublish = false) => {
    setIsModalOpen({
      ...isModalOpen,
      isLoading: true
    })
    let formData = new FormData();
    const videoFiles = [];
    videoFiles.push(...uploadData.selectedFiles.map(x => x.file));
    formData.append("thumbnailImage", uploadData.thumbnailFile)
    videoFiles.forEach((file, index) => {
      formData.append(`techTalkVideos`, file);
    });
    let videoDetails = uploadData.selectedFiles && uploadData.selectedFiles.map((fileInfo, index) => {
      return ({ "VideoName": fileInfo.file.name, "FilePath": fileInfo.file.path, "OrderNumber": index + 1, isAdded: fileInfo.isAdded, isDeleted: fileInfo.isDeleted })
    })
    let body = {
      title: uploadData.name,
      subCategoryName: uploadData.subCategory.value,
      createdBy: parseInt(parsedObject["decoded"]["Id"]),
      tags: state.selectedTechIds?.length !== 0 ? state.selectedTechIds?.map((id) => ({ id: id, })) : null,
      description: uploadData.description,
      totalVideoCount: uploadData.totalVideos,
      totalVideosLength: `${uploadData.videoHrs || 0} hours ${uploadData.videoMins || 0} minutes`,
      totalPdfCount: uploadData?.totalPdf ? parseInt(uploadData?.totalPdf) : 0,
      totalPdfPages: uploadData?.pdfPages ? parseInt(uploadData?.pdfPages) : 0,
      totalPptCount: uploadData?.totalPPT ? parseInt(uploadData?.totalPPT) : 0,
      totalPptPages: uploadData?.PPTPages ? parseInt(uploadData?.PPTPages) : 0,
      instructorId: parseInt(uploadData.instructorId),
      isAutoArchive: uploadData.autoArchive,
      isPublished: isPublish,
      videoDetails: videoDetails,
      isDraft:isModalOpen.draft
    }
    // tech talk json
    formData.append("techTalkInfoJSON", JSON.stringify(body));
    try {
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/create`
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
        "content-Type": "multipart/form-data"
      }
      const response = await axios.post(url, formData, { headers, maxContentLength: 150000000 });
      if (response.data.success == 200) {
        setUploadData(TechTalkState);
        setIsModalOpen(() => ({
          popUp: false,
          isLoading: false,
          draft: false
        }));
        setIsOpen(() => ({
          popUp: false,
          isLoading: false,
          isModalOpen: true,
          modalType: "success",
          modalTitle: isPublish == false ? "Tech Talk Saved Successfully" : "Tech Talk Uploaded Successfully",
          modalMessage: isPublish == false ? "Tech Talk has been Saved Successfully" : "Tech Talk Uploaded Successfully",
          icon: <SuccessfullVector />,
        }));
      } else {
        setIsModalOpen(() => ({
          popUp: false,
          isLoading: false,
          draft: false
        }));
        setIsOpen(() => ({
          popUp: false,
          isLoading: false,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: response.data.message,
          icon: <AlertInfo />,
        }));
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      // handleApiError(statusCode, navigate);
    }
  };
  const dataValidation = () => {
    const { currPage, error, thumbnail, totalPPT, totalPdf, totalVideos, pdfPages, PPTPages, isSavedClicked, videoHrs, videoMins, instructorId, subCategory, category } = uploadData;
    switch (currPage) {
      case 1:
        return error.name || error.description || state.selectedTechIds.length > 10 || thumbnail === "" || subCategory == null || category == null;
      case 2:
        return !(totalVideos > 0) || !isSavedClicked
      case 4:
        return ((videoHrs == null || videoHrs == "") && (videoMins == null || videoMins == "")) || (videoHrs == 0 && videoMins == 0) || (videoHrs ==0 && (videoMins == "" || videoMins == null)) || ((videoHrs==null|| videoHrs=="") && videoMins ==0 ) || (totalPdf > 0 && pdfPages == 0) || (totalPPT > 0 && PPTPages == 0) || instructorId == 0 || instructorId == undefined;
      default:
        return true;
    }
  };
  const parseTime = input => {
    const parts = input.split(' ');
    let hours = 0;
    let minutes = 0;

    parts.forEach((part, index) => {
      if (part === 'hours') {
        hours = parseInt(parts[index - 1]);
      } else if (part === 'minutes') {
        minutes = parseInt(parts[index - 1]);
      }
    });

    return { hours, minutes };
  };
  const getTechTalkById = async () => {
    const techTalkId = sessionStorage.getItem("techTalkId");
    let url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/getById?techTalk=${techTalkId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    }
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success == 200) {
        let data = response.data.result
        const tagIds = data.tags && data.tags.map((tag) => tag.id);
        const tagNames = data.tags && data.tags.map((tag) => tag.techName);
        dispatch({ type: TechActionType.SET_TECH_NAME, payload: tagNames });
        dispatch({ type: TechActionType.SET_TECH_ID, payload: tagIds });

        const sortedVideo = data?.videoDetails != null
          ? data?.videoDetails?.sort((a, b) => a.orderNumber - b.orderNumber)
          : []
        const files = sortedVideo && sortedVideo.map(file => ({
          file: {
            id: file?.videoId || "00000000-0000-0000-0000-000000000000",
            name: file?.videoName,
            path: file?.videoPath,
          },
          isAdded: file?.isAdded,
          isDeleted: file?.isDeleted,
          orderNumber: file?.orderNumber
        }));
        setUploadData((prevState) => {
          const parsedTime = parseTime(data.totalVideosLength);
          return {
            ...prevState,
            id: data.id,
            name: data.title,
            description: data.description,
            thumbnail: data.thumbnailUri,
            thumbnailOldUri: data.thumbnailName,
            tags: data.tags && data.tags.map(x => x.techName),
            category: {
              value: data.categoryName,
              label: data.categoryName,
              id: data.categoryId,
            },
            subCategory: {
              value: data.subCategoryName,
              label: data.subCategoryName,
              id: data.subCategoryId,
            },
            selectedFiles: files,
            instructor: data?.instructorId && data.instructorName,
            instructorId: data?.instructorId,
            autoArchive: data?.isAutoArchive,
            totalVideos: data?.totalVideoCount,
            isPublish: data.isPublished,
            videoHrs: data.totalVideoCount != "" && parsedTime.hours.toString(),
            videoMins: data.totalVideoCount != "" && parsedTime.minutes.toString(),
            totalPdf: data?.totalPdfCount?.toString(),
            pdfPages: data?.totalPdfPages?.toString(),
            totalPPT: data?.totalPptCount?.toString(),
            PPTPages: data?.totalPptPages?.toString(),
            isSavedClicked: true
          };
        });
      }
      else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  }
  const handleEditPublish = async (isPublish = false) => {
    setIsModalOpen({
      ...isModalOpen,
      isLoading: true
    })
    let formData = new FormData();
    if (uploadData.isThumbNailChanged)
      formData.append("thumbnailImage", uploadData.thumbnailFile)

    uploadData.selectedFiles.map(file => {
      if (file.isAdded)
        formData.append(`techTalkVideos`, file.file);
    });

    let videoDetails = uploadData.selectedFiles && uploadData.selectedFiles.map((fileInfo, index) => {
      return ({ "VideoId": fileInfo.file.id, "VideoName": fileInfo.file.name, "FilePath": fileInfo.file.path, "OrderNumber": index + 1, isAdded: fileInfo.isAdded, isDeleted: fileInfo.isDeleted })
    });
    let body = {
      id: uploadData.id,
      title: uploadData.name,
      subCategoryName: uploadData.subCategory.value,
      createdBy: parseInt(parsedObject["decoded"]["Id"]),
      tags: state.selectedTechIds?.length !== 0 ? state.selectedTechIds?.map((id) => ({ id: id, })) : null,
      description: uploadData.description,
      isThumbNailChanged: uploadData.isThumbNailChanged,
      isAutoArchive: uploadData.autoArchive,
      thumbnailUri: uploadData.thumbnailOldUri,
      totalVideoCount: uploadData.totalVideos,
      totalVideosLength: `${uploadData.videoHrs || 0} hours ${uploadData.videoMins || 0} minutes`,
      instructorId: uploadData.instructorId ? parseInt(uploadData.instructorId) : 0,
      isPublished: isPublish,
      videoDetails: videoDetails,
      isDraft: isModalOpen.draft,
      totalPptCount: uploadData?.totalPPT ? parseInt(uploadData?.totalPPT) : 0,
      totalPdfCount: uploadData?.totalPdf ? parseInt(uploadData?.totalPdf) : 0,
      totalPdfPages: uploadData?.pdfPages ? parseInt(uploadData?.pdfPages) : 0,
      totalPptPages: uploadData?.PPTPages ? parseInt(uploadData?.PPTPages) : 0,
    }
    formData.append("techTalkInfoJSON", JSON.stringify(body));
    try {
      const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/update`
      const headers = {
        Authorization: `Bearer ${parsedObject["token"]}`,
        "Content-Type": "multipart/form-data"
      }
      const response = await axios.post(url, formData, { headers, maxContentLength: 150000000 });
      if (response.data.success == 200) {
        setIsModalOpen(() => ({
          popUp: false,
          isLoading: false,
          draft: false
        }));
        setIsOpen(() => ({
          popUp: false,
          isLoading: false,
          isModalOpen: true,
          modalType: "success",
          modalTitle: isPublish == false ? "Tech Talk Saved Successfully" : "Tech Talk Uploaded Successfully",
          modalMessage: isPublish == false ? "Tech Talk has been Saved Successfully" : "Tech Talk Uploaded Successfully",
          icon: <SuccessfullVector />,
        }));
      } else {
        setIsModalOpen(() => ({
          popUp: false,
          isLoading: false,
          draft: false
        }));
        setIsOpen(() => ({
          popUp: false,
          isLoading: false,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: response.data.message,
          icon: <AlertInfo />,
        }));
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  const handleClose = () => {
    if (isOpen.modalType === "success") {
      navigate("/admin/lms/all-techTalk");
    }
    setIsOpen((prevState) => ({
      ...prevState,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const isValidStatus = status === 'add' || status === 'edit';
  if (!isValidStatus) {
    navigate("/error/statusCode=400")
  }
  if (isValidStatus && status === 'edit' && techTalkId == null) {
    navigate("/admin/lms/all-techTalk/add")
  }

  useEffect(() => {
    techTalkId && getTechTalkById()
    const handleRouteChange = () => {
      sessionStorage.clear();
      setUploadData(TechTalkState);
      dispatch({
        type: TechActionType.RESET,
        payload: "",
      });
    };
    return () => {
      handleRouteChange();
    };
  }, [location.pathname, status])

  const handleConfirmPublish = () => {
    if (uploadData.id)
      handleEditPublish(true);
    else
      handlePublish(true);
  }
  const handleSaveAsDraft = async () => {
    if (uploadData.id) {
      return await handleEditPublish();
    }
    await handlePublish();
  }
  const draft = () => {
    if (uploadData.name && uploadData.subCategory && uploadData.category && uploadData.description && state.selectedTechIds.length && uploadData.thumbnail)
      return false
    return true
  }
  const uploadBodyRef = useRef(null);

  const resetScrollPosition = () => {
    if (uploadBodyRef.current) {
      uploadBodyRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    resetScrollPosition();
  }, [uploadData.currPage]);

  return (
    <>
      <Header />
      <div style={{ marginBottom: "4rem" }}>
        <SideNav />
      </div>
      <div className="container tt-wrapper">
        <div className="row">
          <div className="detailrep-header">
            <div className="detailrep-breadcrum">
              <BreadCrumbs />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <div className="upload-courses-container">
              {uploadData.id ?
                <p>Edit Tech Talk</p> :
                <p>Add Tech Talk</p>}
              <button
                disabled={uploadData.isPublish ? true : draft() && true}
                type="button"
                className="btn save-as-draft-btn mb-2"
                onClick={() => setIsModalOpen({ ...isModalOpen, draft: true })}
              >
                <Draft />
                Save As Draft
              </button>
            </div>
            <div className="card add-course-card d-flex">
              <FileUploadProgress currPage={uploadData.currPage} type={uploadData.type} />

              <div className="card-body w-100 pt-0 tt-body-wrapper" ref={uploadBodyRef}>
                {(() => {
                  switch (uploadData.currPage) {
                    case 1:
                      return <TechTalkInfo uploadData={uploadData} setUploadData={setUploadData} state={state} dispatch={dispatch} />;
                    case 2:
                      return <TechTalkVideo uploadData={uploadData} setUploadData={setUploadData} />;
                    case 4:
                      return <AdditionalInfo type={uploadData.type} uploadData={uploadData} setUploadData={setUploadData} />;
                    default:
                      return "null";
                  }
                })()}
              </div>
              <FileUploadFooter uploadData={uploadData} setUploadData={setUploadData} type={uploadData.type} setIsModalOpen={setIsModalOpen} validationFunction={dataValidation} />
            </div>
          </div>
          <div className="col-3">
            <UploadTips type={uploadData.type} />
          </div>
        </div>
      </div>
      {isModalOpen.popUp && <PublishPopUp confirmPublish={handleConfirmPublish} isOpen={isModalOpen} setIsOpen={setIsModalOpen} type={"Tech Talk"} />}
      {isModalOpen.draft && <DraftComp confirmArchive={handleSaveAsDraft} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />}
      {isOpen.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: isOpen.icon,
            show: isOpen.isModalOpen,
            type: isOpen.modalType,
            title: isOpen.modalTitle,
            message: isOpen.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
    </>
  );
};

export default TechTalk;
