import "../CertificatesList/CertificatesList.css";
import { useState, useEffect, SearchIcon, useNavigate, axios, handleApiError, decryptData, CertificateListSkeleton, EmptyCertificate,TotalCourses} from "../../AdminImport";

const CertificatesList = (props) => {
  const {setCertificateData, setCertificateDataLoading } = props;
  const [certificateCountList, setCertificateCountList] = useState({
    isLoading: true,
    originalData: [],
    filterData: [],
  });
  const [selectedDiv, setSelectedDiv] = useState(null);
  const navigate = useNavigate();

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleOnUserclick = async (categoryId, SubcategoryId, divId) => {
    setCertificateDataLoading(true);
    setSelectedDiv(divId);
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Milestone/getAllUserMilestoneByCategorySubCategoryIdAndUserId?categoryId=${categoryId}&subCategoryId=${SubcategoryId}&userId=0`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      setCertificateData(response.data.result);
      setCertificateDataLoading(false);
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleFiltersChange = (e) => {
    var searchValue = e.target.value.toLowerCase();
    const getFilterItems = (items, searchValue, keyValue) => {
      if (searchValue) {
        return items.filter((item) => item?.[keyValue].toLowerCase().includes(searchValue.toLowerCase()));
      }
      return items;
    };
    const filters = getFilterItems(certificateCountList.originalData, searchValue, "subCategoryName");
    setCertificateCountList((prevState) => ({
      ...prevState,
      filterData: filters,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let url = `${process.env.REACT_APP_API_URL_DOTNET}/Dashboard/LMSDashboard`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        setCertificateCountList((prevState) => ({
          ...prevState,
          originalData: response.data.result.milestonesCount,
          filterData: response.data.result.milestonesCount,
          isLoading: false,
        }));

        if (response.data.result.milestonesCount.length > 0) {
          handleOnUserclick(response.data.result.milestonesCount[0].categoryId, response.data.result.milestonesCount[0].subCategoryId, `div_0`);
        }else{
          setCertificateDataLoading(false);
        }
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  return (
    <>
      <div>
        <p className="certification-head">Certifications Achieved</p>
        <div className="card certificte-card">
          <div className="d-flex align-items-center ps-1 certificate-search-wrapper">
            <SearchIcon />
            <input
              className="search-certificate"
              type="text"
              placeholder="Search By Technology"
              onChange={(e) => {
                e.preventDefault();
                handleFiltersChange(e);
              }}
            />
          </div>
          <div className="h-100" style={{ overflowY: "scroll", overflowX: "hidden" }}>
            {certificateCountList.isLoading ? (
              Array.from({ length: 10 }).map((_, index) => <CertificateListSkeleton key={index} />)
            ) : certificateCountList.filterData?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center flex-column h-100">
                <EmptyCertificate />
                <p className="fw-bold mt-3 mb-1 empty-certificate-text">No certificates at this moment</p>
                <p className="empty-certificate-fade-text mt-1">We’ll notify you when something arrives.</p>
              </div>
            ) : (
              certificateCountList.filterData.map((certificate, index) => (
                <div
                  key={certificate.subCategoryId}
                  id={`div_${index}`} // Assign unique ID to each div
                  className={`d-flex align-items-center fw-600 pointer milestone-count-card ${selectedDiv === `div_${index}` ? 'selected' : ''}`} // Add 'selected' class if div is clicked
                  onClick={() => {
                    handleOnUserclick(certificate.categoryId, certificate.subCategoryId, `div_${index}`);
                  }}
                >
                  <div className="milestone-image">
                    <img src={certificate.subCategoryImageLink!=null && certificate.subCategoryImageLink!="" ? `data:image/svg+xml;base64,${certificate.subCategoryImageLink}` : TotalCourses} width={28} height={28} alt={certificate.subCategoryName} />
                  </div>
                  <div className="d-flex milestone-name-count">
                    <p className="m-name mb-0 ">{certificate.subCategoryName}</p>
                    <p className="m-count mb-0">{certificate.totalCertificationCount.toString().padStart(2, "0")}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CertificatesList;
