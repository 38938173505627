import { CourseEnrolled, CourseCheck, CourseWarning, CourseFlag,React } from "./../UploadCoursesImport";

const ProgressBar = ({ completedCount, totalCount, courseStatus }) => {
  const progress = (completedCount / totalCount) * 100;

  const lowerCaseStatus = courseStatus?.toLowerCase();

  const statusMap = {
    overdue: {
      color: "#E03E2D",
      icon: <CourseWarning />,
    },
    ongoing: {
      color: "#28519E",
      icon: <CourseFlag />,
    },
    completed: {
      color: "#2DC26B",
      icon: <CourseCheck />,
    },
    enrolled: {
      color: "#DDDBDA",
      icon: <CourseEnrolled />,
    },
  };

  const { color, icon } = statusMap[lowerCaseStatus] || { color: "#000", icon: null };

  return (
    <div>
      <div className="progress empty-progress-color progress-empty" >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${progress}%`,
            backgroundColor: color,
            borderRadius: "5px",
          }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex justify-content-between align-item-center" style={{ marginTop: "5px" }}>
      <div className="d-flex justify-content-center">
          <div style={{ marginLeft: "5px" }}>{icon}</div>
          <div className="ms-1">
            <p>{courseStatus}</p>
          </div>
        </div>
        <div>
          <p>
            {(completedCount/totalCount)*100}% Completed
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
