import { useTable, useBlockLayout } from "react-table";
import { useSticky } from "react-table-sticky";
import { COLUMNS } from "./TotalUploadedCoursesTableColumns";
import { useEffect, useState, useMemo, Skeleton, decryptData, useNavigate, handleApiError, axios, toast, AdditionalInfoInstructorIcon, AdditionalInfoAutoIcon, AdditionalInfoPublishedIcon, ReactModal, Form, AdditionalInfoDraftIcon, EmptyTotalUploadedCoursesTable, AdditionalInfoUsersIcon, CourseEdit, AssignCourseButton, AdditionalInfoArchiveIcon, AdditionalInfoArchivedStatus, CourseContent, EnrollmentStatusSkeleton, RestoreIcon, ChevronUp, ChevronDown } from "../UploadCoursesImport";
import "./TotalUploadedCourses.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

const TotalUploadedCoursesTable = (props) => {
  const navigate = useNavigate();
  const { tableData, archiveCourse, isLoading, setTaskToArchive } = props;
  const [tableState, setTableState] = useState({
    selectedIndex: -1,
    expandedRowIndex: null,
    expandedRowData: {},
    expandedRows: [],
    resultsPerPage: 10,
    currentPage: 1,
  });
  const [filterState, setFilterState] = useState({
    query: "",
    filteredUsers: [],
    orgFilteredUsers: [],
    selectedUsersId: [],
    isUsersLoading: true,
    selectedCourseId: "",
    selectedCourseName: "",
    selectedCourseDescription: "",
  });
  const [isReadMoreModal, setIsReadMoreModal] = useState({
    showModal: false,
    selectedItems: null
  });

  let parsedObject;
  const pData = localStorage.getItem("userData");
  pData ? (parsedObject = decryptData(pData, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleSearchChange = (query) => {
    const filtered = filterState.orgFilteredUsers.filter((user) => user.firstName.toLowerCase().startsWith(query.toLowerCase()));
    setFilterState({ ...filterState, filteredUsers: filtered });
  };

  const totalItems = tableData?.length;
  const totalPaginationPages = Math.ceil(totalItems / tableState.resultsPerPage);

  const filteredTableData = useMemo(() => {
    return tableData;
  }, [tableData]);

  const arrayStartIndex = (tableState.currentPage - 1) * tableState.resultsPerPage;
  const arrayEndIndex = arrayStartIndex + tableState.resultsPerPage;
  const arrayCurrentResults = useMemo(() => {
    return filteredTableData?.slice(arrayStartIndex, arrayEndIndex);
  }, [filteredTableData, arrayStartIndex, arrayEndIndex]);

  const handlePageChange = (pageNumber) => {
    setTableState({
      ...tableState,
      currentPage: pageNumber,
    });
    window.scrollTo(0, 0);
  };

  const handlePrevPageChange = () => {
    if (tableState.currentPage != 1) {
      setTableState({
        ...tableState,
        currentPage: tableState.currentPage - 1,
      });
    }
    window.scrollTo(0, 0);
  };

  const handleNextPageChange = () => {
    if (tableState.currentPage != totalPaginationPages) {
      setTableState({
        ...tableState,
        currentPage: tableState.currentPage + 1,
      });
    }
    window.scrollTo(0, 0);
  };

  const handleResultsPerPageChange = (event) => {
    setTableState({
      ...tableState,
      resultsPerPage: event.target.value,
      currentPage: 1,
    });
  };

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => arrayCurrentResults, [arrayCurrentResults]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useBlockLayout, useSticky);

  const toggleRowExpansion = (rowId) => {
    setTableState((prevState) => {
      const newExpandedRows = prevState.expandedRows.includes(rowId) ? prevState.expandedRows.filter((id) => id !== rowId) : [...prevState.expandedRows, rowId];

      return { ...prevState, expandedRows: newExpandedRows };
    });
  };

  const truncate = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  if (filteredTableData == undefined || filteredTableData?.length === 0) {
    const table = document.querySelector(".table.empty-course-table");
    if (table) {
      table.scrollLeft = 0;
    }
  }

  const getExpandedData = () => {
    const expandedData = {};
    tableState?.expandedRows?.forEach((rowId) => {
      const expandedRow = rows.find((row) => row.id === rowId);
      if (expandedRow) {
        expandedData[rowId] = expandedRow.original;
      }
    });
    setTableState({
      ...tableState,
      expandedRowData: expandedData,
    });
  };
  const userCourseEnrollmentStatus = async (courseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getUsers?courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        setFilterState((prevState) => ({
          ...prevState,
          filteredUsers: response.data.result,
          orgFilteredUsers: response.data.result,
          isUsersLoading: false,
          selectedCourseId: courseId
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleReadMore = (item) => {
    setIsReadMoreModal((prevState) => ({
      ...prevState,
      selectedItems: item,
      showModal: true
    }));
  };

  const handleAssignCourse = async (e) => {
    e.preventDefault();
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/manageEnrollment`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      courseId: filterState.selectedCourseId,
      userId: filterState.selectedUsersId,
      assignedById: parsedObject["decoded"]["Id"],
      isAssigned: true,
      courseName: filterState.selectedCourseName,
      description: filterState.selectedCourseDescription
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.data.Success === 200 || response.data.success === 200) {
        setFilterState((prevState) => ({
          ...prevState,
          query: "",
          selectedUsersId: [],
        }));
        setTaskToArchive((prevState) => ({
          ...prevState,
          courseVersion: prevState.courseVersion + 1,
        }));
        toast.success("User assigned successfully !");
      } else {
        throw new Error(`Unexpected response status: ${response.data.success}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleUsersChange = (e, c) => {
    if (e.target.checked) {
      setFilterState({
        ...filterState,
        selectedUsersId: [...filterState.selectedUsersId, c.id],
      });
    } else if (!e.target.checked) {
      setFilterState({
        ...filterState,
        selectedUsersId: filterState.selectedUsersId.filter((item) => item !== c.id),
      });
    }
  };

  const handleSelectAllChange = (e) => {
    const existingSelectedUsersId = filterState.selectedUsersId || [];

    const unenrolledUserIds = filterState.filteredUsers.filter((user) => !user.isActive && !existingSelectedUsersId.includes(user.id)).map((user) => user.id);

    if (e.target.checked) {
      setFilterState((prevState) => ({
        ...prevState,
        selectedUsersId: [...existingSelectedUsersId, ...unenrolledUserIds],
      }));
    } else if (!e.target.checked) {
      setFilterState({
        ...filterState,
        selectedUsersId: filterState.selectedUsersId.filter((item) => !existingSelectedUsersId.includes(item)),
      });
    }
  };

  useEffect(() => {
    handleSearchChange(filterState.query);
  }, [filterState.query]);

  useEffect(() => {
    getExpandedData();
  }, [tableState.expandedRows, rows]);

  return (
    <>
      <div {...getTableProps()} className={`table sticky dp-scroll mb-0 ${filteredTableData == undefined || filteredTableData.length === 0 ? "empty-course-table" : ""}`}>
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr ">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className={`th ${column.id === "action" ? "d-flex justify-content-center " : ""}`}>
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {isLoading ? (
            <>
              <Skeleton height={56} />
              <hr />
              <Skeleton height={56} />
              <hr />
              <Skeleton height={56} />
              <hr />
              <Skeleton height={56} />
              <hr />
              <Skeleton height={56} />
              <hr />
            </>
          ) : filteredTableData == undefined || filteredTableData?.length === 0 ? (
            <EmptyTotalUploadedCoursesTable />
          ) : (
            rows.map((row) => {
              prepareRow(row);
              const isRowExpanded = tableState.expandedRows.includes(row.id);
              return (
                <>
                  <div key={row.id} {...row.getRowProps()} className={`tr ${isRowExpanded ? "active-row" : ""}`}>
                    {row.cells.map((cell, cellIndex) => (
                      <div key={cellIndex} {...cell.getCellProps()} className={`td d-flex align-items-center ${cell.row.original.isActive == false ? "archived-course-row" : ""}`}>
                        {cellIndex === 0 ? (
                          <>
                            {isRowExpanded && tableState?.expandedRowData[row.id] ? <div className="total-uploaded-table-chev" onClick={() => toggleRowExpansion(row.id)}><ChevronUp className="d-flex justify-content-center align-items-center total-uploaded-courses-down-arrow"/></div> : <div className="total-uploaded-table-chev" onClick={() => toggleRowExpansion(row.id)}><ChevronDown className="d-flex justify-content-center align-items-center total-uploaded-courses-right-arrow"/></div>}
                          </>
                        ) : cellIndex === 1 ? (
                          isLoading ? (
                            <Skeleton width={50} height={20} />
                          ) : (
                            <span>
                              {tableState.expandedRowIndex === cell.id ? (
                                cell.row.values.title
                              ) : (
                                <div className="fw-bold">
                                  {truncate(cell.row.values.title, 55)}
                                  {cell.row.values.title.length > 55 && (
                                    <span
                                      className="text-decoration-underline total-uploaded-courses-read-more total-uploaded-courses-cursor-pointer fw-bold"
                                      onClick={() => {
                                        handleReadMore(cell.row.values);
                                      }}
                                    >
                                      Read more
                                    </span>
                                  )}
                                </div>
                              )}
                            </span>
                          )
                        ) : cellIndex === 4 ? (
                          <span>
                            {tableState.expandedRowIndex === row.id ? (
                              cell.row.values.description
                            ) : (
                              <>
                                {truncate(cell.row.values.description, 55)}
                                {cell.row.values.description.length > 55 && (
                                  <span
                                    className="text-decoration-underline total-uploaded-courses-read-more total-uploaded-courses-cursor-pointer"
                                    onClick={() => {
                                      handleReadMore(cell.row.values);
                                    }}
                                  >
                                    Read more
                                  </span>
                                )}
                              </>
                            )}
                          </span>
                        ) : cellIndex === 5 ? (
                          <div className={`badge ${cell.row.values.levelName === "Beginner" ? "total-uploaded-courses-beginner-tech-badge" : cell.row.values.levelName === "Intermediate" ? "total-uploaded-courses-intermediate-tech-badge" : "total-uploaded-courses-advance-tech-badge"}`}>{cell.row.values.levelName === "Advance" ? "Advanced" : cell.row.values.levelName}</div>
                        ) : cellIndex === 6 ? (
                          <div
                            className="total-uploaded-courses-tech-tags"
                            onMouseLeave={() =>
                              setTableState({
                                ...tableState,
                                selectedIndex: -1,
                              })
                            }
                          >
                            {cell.row.values.tags?.slice(0, tableState.selectedIndex === row.id ? cell.value?.length : 2).map((tag, index) => {
                              return tag.techName === null ? (
                                <div key={index}></div>
                              ) : (
                                <div key={index} className="total-uploaded-courses-tag-tech d-flex justify-content-center align-items-center">
                                  <span>{tag.techName}</span>
                                </div>
                              );
                            })}
                            {tableState.selectedIndex !== row.id && cell.row.values.tags != null && cell.row.values.tags.slice(2).length !== 0 && (
                              <div
                                className="total-uploaded-courses-all-tech"
                                onMouseEnter={() =>
                                  setTableState({
                                    ...tableState,
                                    selectedIndex: row.id,
                                  })
                                }
                              >
                                <span>+ {cell.row.values.tags?.slice(2).length}</span>
                              </div>
                            )}
                          </div>
                        ) : cellIndex === 7 ? (
                          <div className="d-flex justify-content-center w-75">
                            {cell.render("Cell")}
                          </div>
                        ) : cellIndex === 8 ? (
                          !cell.row.values.courseCompletionTime ? (
                            "NA"
                          ) : (
                            <span>{cell.row.values.courseCompletionTime?.replace(/(\d+) days (\d+) hrs (\d+) mins/, (_, d, h, m) => `${Number(d) * 24 + Number(h)} hr(s) ${m} min(s)`)}</span>
                          )
                        ) : cellIndex === 9 ? (
                          cell.row.values.instructorList === null || cell.row.values.instructorList?.length === 0 ? (
                            "NA"
                          ) : (
                            <span>
                              {cell.row.values.instructorList?.map((instructorName, index, array) => (
                                <>
                                  {instructorName.lastName ? `${instructorName.firstName} ${instructorName.lastName}` : `${instructorName.firstName}`}
                                  {index !== array.length - 1 ? ", " : ""}
                                </>
                              ))}
                            </span>
                          )
                        ) : cellIndex === 10 ? (
                          cell.row.values.gradeType === "Not Selected" ? (
                            "NA"
                          ) : (
                            <div className={`d-flex align-items-center badge total-uploaded-courses-instructor-grade-badge ${cell.row.values.gradeType === "Auto-grade" ? "total-uploaded-courses-instructor-auto-grade" : ""}`}>
                              {cell.row.values.gradeType === "Instructor Grade" ? <AdditionalInfoInstructorIcon /> : cell.row.values.gradeType === "Auto-grade" ? <AdditionalInfoAutoIcon /> : null}
                              <div>{cell.row.values.gradeType === "Instructor Grade" ? "Instructor" : "Auto"}</div>
                            </div>
                          )
                        ) : cellIndex === 12 ? (
                          !cell.row.values.createdDate ? (
                            "NA"
                          ) : (
                            cell.row.values.createdDate && <span>{new Date(cell.row.values.createdDate).toLocaleDateString("en-GB").replace(/\//g, "-")}</span>
                          )
                        ) : cellIndex === 13 ? (
                          !cell.row.values.modifiedDate ? (
                            "NA"
                          ) : (
                            cell.row.values.modifiedDate && <span>{new Date(cell.row.values.modifiedDate).toLocaleDateString("en-GB").replace(/\//g, "-")}</span>
                          )
                        ) : cellIndex === 14 ? (
                          <div className={`d-flex align-items-center badge total-uploaded-courses-instructor-grade-badge ${cell.row.original.isActive === false ? "total-uploaded-courses-instructor-status-active" : cell.row.values.isPublished === true ? "total-uploaded-courses-instructor-status-published" : "total-uploaded-courses-instructor-status"}`}>
                            {cell.row.original.isActive === false ? <AdditionalInfoArchivedStatus /> : cell.row.original.isPublished === true ? <AdditionalInfoPublishedIcon /> : <AdditionalInfoDraftIcon />}
                            <div>{cell.row.original.isActive === false ? "Archived" : cell.row.original.isPublished ? "Published" : "Draft"}</div>
                          </div>
                        ) : cellIndex === 15 ? (
                          <div className="d-flex align-items-center ms-3">
                            <button
                              className="btn p-0 me-2 assign-course-btn"
                              type="button"
                              disabled={cell.row.values.userEnrolledCount === 0}
                              onClick={() => {
                                const courseId = cell.row.original.id;
                                sessionStorage.setItem("cId", courseId);
                                sessionStorage.setItem("cName", cell.row.original.title)
                                navigate("/admin/lms/enrolled-user");
                              }}
                            >
                              <AdditionalInfoUsersIcon className={`${cell.row.values.userEnrolledCount === 0 ? "edit-course-image" : ""}`} />
                            </button>
                            <div className={`total-uploaded-courses-enrolled-user ms-1 ${cell.row.values.userEnrolledCount === 0 ? "disabled-enrolled-text" : ""}`}>{cell.row.values.userEnrolledCount}</div>
                          </div>
                        ) : cellIndex === 16 ? (
                          <div >
                            <button className="btn p-0 me-2 assign-course-btn" type="button"
                              onClick={() => {
                                setFilterState((prevState) => ({
                                  ...prevState,
                                  selectedCourseName: cell.row.original.title,
                                  selectedCourseDescription: cell.row.original.description
                                }));
                                userCourseEnrollmentStatus(cell.row.original.id)
                              }}
                              data-bs-toggle="modal" data-bs-target="#assignCourseModal" disabled={cell.row.original.isAssignButtonDisabled}>
                              <AssignCourseButton className={`${cell.row.original.isAssignButtonDisabled ? "assign-course-image" : ""}`} />
                            </button>
                            <button
                              className="btn p-0 me-2 assign-course-btn"
                              type="button"
                              disabled={cell.row.original.isEditButtonDisabled}
                              onClick={() => {
                                sessionStorage.setItem("courseId", cell.row.original.id);
                                navigate("/admin/lms/editCourse");
                              }}
                            >
                              <CourseEdit className={`${cell.row.original.isEditButtonDisabled ? "edit-course-image" : ""}`} />
                            </button>
                            <button className="btn p-0 assign-course-btn" type="button" disabled={cell.row.original.isArchiveButtonDisabled || cell.row.original.isPublished === false}>
                              {cell.row.original.isActive ? (
                                <AdditionalInfoArchiveIcon
                                  courseId={cell.row.original.id}
                                  onClick={(e) => {
                                    archiveCourse(e, cell.row.original.id, cell.row.original.title, cell.row.original.isActive);
                                  }}
                                  className={`${cell.row.original.isArchiveButtonDisabled ? "archive-course-image" : ""}`}
                                />
                              ) : (
                                <RestoreIcon
                                  courseId={cell.row.original.id}
                                  onClick={(e) => {
                                    archiveCourse(e, cell.row.original.id, cell.row.original.title, cell.row.original.isActive);
                                  }}
                                />
                              )}
                            </button>
                          </div>
                        ) : (
                          cell.render("Cell")
                        )}
                      </div>
                    ))}
                  </div>
                  {isRowExpanded && tableState?.expandedRowData[row.id] && <CourseContent row={row} tableState={tableState} setTableState={setTableState} truncate={truncate} />}
                </>
              );
            })
          )}
        </div>
      </div>
      <div className="modal fade" id="assignCourseModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="assignCourseModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <h5 className="modal-title assign-course-modal-heading" id="assignCourseModalLabel">
                Assign Courses
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  setFilterState((prevState) => ({
                    ...prevState,
                    query: "",
                    selectedUsersId: [],
                  }))
                }
              ></button>
            </div>
            <div className="modal-body">
              <div className="total-uploaded-courses-assign-search mb-2">
                <div className="total-uploaded-courses-search-icon" />
                <input
                  type="text"
                  className="total-uploaded-courses-input-bar"
                  placeholder="Search Name"
                  value={filterState.query}
                  onChange={(e) =>
                    setFilterState({
                      ...filterState,
                      query: e.target.value,
                    })
                  }
                />
              </div>
              <div className="d-flex flex-column align-items-start w-100">
                {filterState.isUsersLoading ? (
                  <>
                    <div className="d-flex justify-content-between w-100">
                      <div className="form-check small checkbox gap-2 mb-2">
                        <Skeleton className="form-check-input user-checkbox" type="checkbox" width={14} />
                        <Skeleton className="form-check-label fw-bold" width={91} />
                      </div>
                      <div className="small form-check-label" style={{ color: "#585E6A" }}>
                        <Skeleton width={50} />
                      </div>
                    </div>
                    <div className="enrolled-status-filter"
                    >
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                      <EnrollmentStatusSkeleton />
                    </div>
                  </>
                ) : (
                  filterState?.filteredUsers &&
                  filterState?.filteredUsers?.length != 0 && (
                    <>
                      <div className="d-flex justify-content-between w-100">
                        <div className="form-check small checkbox gap-2 mb-2">
                          <input className="form-check-input user-checkbox pointer" type="checkbox" id="userDropDownselectAll" checked={filterState.selectedUsersId?.length + filterState.filteredUsers?.filter((user) => user.isActive)?.length === filterState.filteredUsers?.length} onChange={(e) => handleSelectAllChange(e)} />
                          <label className="form-check-label fw-bold pointer" htmlFor="userDropDownselectAll">
                            Select All
                          </label>
                        </div>
                        <div className="small form-check-label" style={{ color: "#585E6A" }}>
                          Total: {filterState.filteredUsers.length}
                        </div>
                      </div>
                      <div className="enrolled-status-filter"
                      >
                        {filterState.filteredUsers &&
                          filterState.filteredUsers.map((c) => (
                            <div className="form-check small checkbox gap-2 mb-2" key={c.id}>
                              <input className="form-check-input user-checkbox pointer" type="checkbox" id={c.id + c.firstName} onChange={(e) => handleUsersChange(e, c)} checked={filterState.selectedUsersId.includes(c.id) || c.isActive} disabled={c.isActive} />
                              <label
                                className="d-flex justify-content-between w-100 pointer form-check-label"
                                htmlFor={c.id + c.firstName}
                              >
                                <span>{`${c.firstName} ${c.lastName != null ? c.lastName : ""}`}</span>
                                <span>{c.designation}</span>
                              </label>
                            </div>
                          ))}
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
            <ReactModal size="lg" dialogClassName="modal-90w" centered show={isReadMoreModal.showModal}
              onHide={() => setIsReadMoreModal((prevState) => ({
                ...prevState,
                showModal: false
              }))}>
              <ReactModal.Header
                closeButton
                style={{
                  borderBottom: "0.063rem",
                }}
              >
                <ReactModal.Title className="fw-bold" style={{ fontSize: "1.1rem" }}>Course Info</ReactModal.Title>
              </ReactModal.Header>
              <ReactModal.Body>
                <p>
                  <span className="fw-bold">Title :</span> <span className="opacity-75">{isReadMoreModal.selectedItems && isReadMoreModal.selectedItems.title}</span>
                </p>

                <Form.Group>
                  <Form.Label>
                    <span className="fw-bold">Description : </span>
                  </Form.Label>
                  <Form.Control className="opacity-75" disabled as="textarea" style={{ fontSize: "0.813rem" }} defaultValue={isReadMoreModal.selectedItems && isReadMoreModal.selectedItems.description} rows={4} cols={60}></Form.Control>
                </Form.Group>
              </ReactModal.Body>
            </ReactModal>
            <div className="modal-footer border-0 pt-0">
              <button
                type="button"
                className="btn cancel-button"
                data-bs-dismiss="modal"
                onClick={() =>
                  setFilterState((prevState) => ({
                    ...prevState,
                    query: "",
                    selectedUsersId: [],
                  }))
                }
              >
                <span className="cancel-text">Cancel</span>
              </button>

              <button type="button" className="btn btn-primary save-button" data-bs-dismiss="modal" onClick={(e) => handleAssignCourse(e)} disabled={filterState.selectedUsersId && filterState.selectedUsersId.length === 0}>
                <span className="save-text-field">Assign</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {!(filteredTableData == undefined || filteredTableData?.length === 0) && <div className="d-flex justify-content-between mb-3">
        <div className="fw-bold total-uploaded-courses-pagination-total-items">Total Items: {arrayCurrentResults?.length}</div>
        <div className="d-flex justify-content-end total-uploaded-courses-pagination-body">
          <div className="d-flex align-items-center">
            <p className="me-2 mb-0 total-uploaded-courses-pagination-results-per-page">Results per page</p>
            <select className="form-select m-2 total-uploaded-courses-pagination-results-per-page-select" value={tableState.resultsPerPage} onChange={handleResultsPerPageChange}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <ul className="pagination mb-0">
              <li className={`page-item me-1 ${tableState.currentPage === 1 ? "page-item disabled" : ""}`}>
                <button className="page-link" onClick={handlePrevPageChange}>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 1.5L1.5 7L7 12.5" stroke={tableState.currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                  </svg>
                </button>
              </li>
              {Array.from({ length: totalPaginationPages }, (_, i) => i + 1).map((page) => (
                <li key={page} className={`page-item me-1 ${page === tableState.currentPage ? "active" : ""}`}>
                  <button className="page-link rounded courses-pagination-styling fw-bold" onClick={() => handlePageChange(page)}>
                    {page}
                  </button>
                </li>
              ))}
              <li className={`page-item ${tableState.currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                <button className="page-link" onClick={handleNextPageChange}>
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12.5L6.5 7L1 1.5" stroke={tableState.currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>}
    </>
  );
};

export default TotalUploadedCoursesTable;
