import { useState, useNavigate, decryptData, Clock, Form, SizeLimit, PDFIcon, ReactModal, axios, handleApiError, CheckOutLine, useEffect, Skeleton, ActiveAssignment, DOCIcon } from "../../UserImport";

const Assignment = (props) => {
    const { assignmentDetails, blobLink, } = props;
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const [assignmentData, setAssignmentData] = useState({
        assignmentLink: null,
        hostedLink: null,
        error: false,
        isLoading: true,
        isSubmited: false,
        isAssignmentCompleted: false,
        isReviewCompleted: false
    })
    const handleClickClear = () => {
        setModal(false);
        setAssignmentData({
            assignmentLink: null,
            hostedLink: null,
            error: false,
        })
    }
    const handleDataChange = (e) => {
        const { name, value } = e.target;
        const isValidUrl = value.length !== 0 ? value.match(/^https?:\/\//) : true;
        setAssignmentData((prevData) => {
            if (name === "assignment") {
                return {
                    ...prevData,
                    assignmentLink: value,
                    error: !isValidUrl
                };
            } else if (name === "hosted") {
                return {
                    ...prevData,
                    hostedLink: value
                };
            }
            return prevData;
        });
    };

    let parsedObject;
    const data = localStorage.getItem("userData");
    data ? parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY) : navigate("/error/statusCode=400");

    const handleSubmit = async () => {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/submitUserCourseAssignment`;
        const body = {
            courseId: assignmentDetails.activityId,
            assignmentLink: assignmentData.assignmentLink,
            hostedLink: assignmentData.hostedLink,
        }
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        }
        try {
            const response = await axios.post(url, body, { headers });
            if (response.status == 200 || response.data.success == 200) {
                fetchData();
                handleClickClear();
            }
            else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            const statusCode = error.response ? error.response : null;
            handleApiError(statusCode, navigate);
        }
    }

    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getUserCourseAssignment?courseId=${assignmentDetails.activityId}`;
        const headers = {
            Authorization: `Bearer ${parsedObject["token"]}`,
        }
        try {
            const response = await axios.get(url, { headers });
            if (response.data.success == 200 || response.data.success == 204) {
                setAssignmentData({
                    ...assignmentData,
                    hostedLink: response.data.result.hostedLink,
                    assignmentLink: response.data.result.assignmentLink,
                    isLoading: false,
                    isAssignmentCompleted: response.data.result.isAssignmentCompleted,
                    isReviewCompleted: response.data.result.isReviewCompleted
                })
            }
            else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            const statusCode = error.response ? error.response : null;
            handleApiError(statusCode, navigate);
        }
    }

    const removeSpecialCharacters = (filename, length = undefined) => {
        const fileExtension = filename.split('.').pop().toLowerCase();
        let icon;
        switch (fileExtension) {
            case "pdf":
                icon = <PDFIcon />
                break;
            case "docx":
                icon = <DOCIcon />
                break;
            default:
                break;
        }
        const fileNameWithoutExtension = filename.replace(/\.[^/.]+$/, "");
        let cleanFileName = fileNameWithoutExtension.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/gi, ' ');
        if (length) {
            if (cleanFileName.length > length) {
                cleanFileName = cleanFileName.slice(0, length) + '...';
            }
        }
        return { cleanFileName, icon };
    };

    const hideModal = () => {
        setModal(false);
        setAssignmentData({
            assignmentLink: null,
            hostedLink: null,
            error: false,
            isSubmited: false
        })
    }
    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        <div className="assignment-player-wrapper">
            <h3 className="fw-bold ">{assignmentDetails.assignmentDetails.title}&nbsp;<span className="grade-wrapper float-end text-center">{assignmentDetails.details.gradeType}</span></h3>
            <span className="completion-time mb-2">
            <Clock />&nbsp;&nbsp;
            {assignmentDetails.assignmentDetails.completionTime?.replace(
                /^\s*(?:(\d+)\s*days?)?\s*(\d+)?\s*(?:hrs?|hours?)?\s*(\d+)?\s*(?:mins?|minutes?)?\s*$/i,
                (_, d = 0, h = 0, m = 0) => {
                // const days = d ? `${d} day(s)` : "";
                const hours = h ? `${h} hr(s)` : "0 hr(s)";
                const minutes = m ? `${m} min(s)` : "0 min(s)";
                return [hours, minutes].filter(Boolean).join(" ");
                }
            )}
            </span>
            <p className={`p-3 px-0 ${assignmentDetails.assignmentDetails.description.length > 250 && "dp-scroll h-11"}`}>{assignmentDetails.assignmentDetails.description}</p>
            {
                assignmentDetails.assignmentDetails.assignmentAttachment.map((assignment) => (
                    <div className="assignment-wrapper mb-3" title={assignment.name}>
                        <span className="assignment-icon">{removeSpecialCharacters(assignment.name).icon}</span>&nbsp;&nbsp;
                        {removeSpecialCharacters(assignment.name, 33).cleanFileName}
                        <a href={assignment.fullPath} target="_blank" className="btn float-end p-0" >Download </a>
                    </div>
                ))
            }
            {assignmentDetails.details.gradeType === "Instructor Grade" ? (!assignmentData.isAssignmentCompleted ? ("") : (assignmentData.isReviewCompleted ? (<div className="text-success">Grading has been done by the instructor</div>) : (<div className="text-danger">Waiting for the instructor's grading to mark the course as complete</div>))) : ""}
            {/* {(assignmentDetails.details.gradeType === "Instructor Grade" && assignmentData.isAssignentCompleted && !assignmentData.isReviewCompleted) ? <div className="text-danger">Waiting for the instructor's grading to mark the course as complete</div> : ""} */}
            <div className="submit-assignment">  &nbsp;&nbsp; &nbsp;
                {
                    assignmentData.assignmentLink !== null ?
                        <>
                            <span className="assignment-icon"> <ActiveAssignment /></span>
                            <div className="description ms-3">
                                <p className="mb-0">Assignment Link <br />
                                    <span className="fw-normal">
                                        <a href={assignmentData?.assignmentLink} target="_blank" rel="noopener noreferrer">{assignmentData?.assignmentLink.length > 60 ? assignmentData?.assignmentLink.slice(0, 60).concat("...") : assignmentData?.assignmentLink}</a>
                                    </span>
                                </p>
                                <p className="mb-0 mt-2">Hosted Link <br />
                                    <span className="fw-normal">
                                        {assignmentData.hostedLink && assignmentData.hostedLink != "" ? <a href={assignmentData.hostedLink} target="_blank" rel="noopener noreferrer">{assignmentData?.hostedLink.length > 60 ? assignmentData?.hostedLink.slice(0, 60).concat("...") : assignmentData?.hostedLink}</a> : "NA"}
                                    </span>
                                </p>
                            </div>
                            <button type="button" disabled={true}> <CheckOutLine /> Submitted</button>
                        </> :
                        (assignmentData.isLoading) ?
                            <>
                                <Skeleton width={20} height={20} baseColor="#E9ECEB" />
                                <div className="description ms-3">
                                    <p className="mb-0 fw-normal "><Skeleton baseColor="#E9ECEB" /> </p>
                                    <p className="mb-0 fw-normal "><Skeleton baseColor="#E9ECEB" /></p>
                                </div>
                                <Skeleton width={130} height={32} baseColor="#E9ECEB" className="ms-3" />
                            </> :
                            <>
                                <span className="assignment-icon"> <ActiveAssignment /></span>
                                <div className="description ms-3">
                                    <p className="mb-0 fw-normal ">Finished your assignment?</p>
                                    <p className="mb-0 fw-normal ">Please provide the GitHub link or any other URL for the completed assignment.</p>
                                </div>
                                <button type="button" onClick={() => { setModal(true) }}
                                    disabled={assignmentDetails.playList.filter(x => !x.isCompleted).length > 0}
                                >Submit Assignment</button>
                            </>
                }
            </div>

            <ReactModal size="lg" dialogClassName="modal-90w" centered show={modal} onHide={() => { hideModal() }}>
                <ReactModal.Header
                    closeButton
                    style={{
                        borderBottom: "0.063rem solid var(--bs-modal-header-border-color)",
                    }}
                >
                    <ReactModal.Title className="h5 fw-bold ">Submit Assignment</ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                    <p>
                        <span className="fw-bold opacity-75">Course Name</span><br />
                        <span className="fw-bold h6">{assignmentDetails.details?.title}</span>
                    </p>

                    <div className="submission-warning d-flex">
                        <span><SizeLimit /></span>
                        <p>
                            Please check your URLs before submitting, once sent, they can't be edited and will be sent to instructor for grading.
                        </p>
                    </div>
                    <Form.Group className="mt-3">
                        <div className="mb-3">
                            <label
                                htmlFor="assignment-link"
                                className="col-form-label title-text"
                            >
                                Assignemnt Link<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={assignmentData.assignmentLink}
                                id="assignment-link"
                                name="assignment"
                                placeholder="Enter Assignment Link"
                                onChange={(e) => { handleDataChange(e) }}
                            />
                            {assignmentData.error && (
                                <span style={{ color: "red", fontSize: "11px" }}>
                                    Invalid URL or URL does not start with http:// or https://
                                </span>
                            )}

                        </div>
                        <div className="mb-3">
                            <label
                                htmlFor="hosted-link"
                                className="col-form-label title-text"
                            >
                                Hosted Link <i>(optional)</i>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                value={assignmentData.hostedLink}
                                id="hosted-link"
                                name="hosted"
                                placeholder="Enter Hosted Link"
                                onChange={(e) => { handleDataChange(e) }}
                            />
                        </div>
                    </Form.Group>
                </ReactModal.Body>
                <ReactModal.Footer>
                    <div className="">
                        <button
                            type="button"
                            className="btn cancel-button me-3"
                            data-bs-dismiss="modal"
                            onClick={handleClickClear}
                        >
                            <span className="cancel-text"> Cancel </span>
                        </button>
                        {
                            assignmentData.isSubmited ? <button
                                type="button"
                                class="btn btn-primary save-button"
                                disabled={true}
                            >
                                <span className="save-text"> Save </span>
                            </button> : <button
                                type="button"
                                class="btn btn-primary save-button"
                                disabled={assignmentData.assignmentLink == null || assignmentData.error}
                                onClick={(e) => {
                                    setAssignmentData({
                                        ...assignmentData,
                                        isSubmited: true
                                    })
                                    handleSubmit(e);
                                }}
                            >
                                <span className="save-text"> Submit </span>
                            </button>
                        }
                    </div>
                </ReactModal.Footer>
            </ReactModal>
        </div >
    )
}

export default Assignment;