import { useNavigate, useMemo, useState, useEffect, handleApiError, decryptData, TechTalkSkeltonLoading, Filter, SearchIcon, axios, AdditionalInfoArchiveIcon, BreadCrumbs, CourseEdit, Header, SideNav, View, TechTalkFilter, ArchiveCourse, AdditionalInfoUnarchiveIcon, TechTalkEmptyState, AdditionalInfoArchivedStatus, AdditionalInfoPublishedIcon, AdditionalInfoDraftIcon, toast, RestoreIcon, ReactModal, Form } from "../../UploadCourses/UploadCoursesImport";
import "./TechTalkTable.css";
import "react-toastify/dist/ReactToastify.css";

const TechtalkTable = () => {
  const [techTalkData, setTechTalkData] = useState({
    isloading: true,
    originalData: [],
    isFlterOpen: false,
    activeRowIndex: -1
  });
  const [archiveTechTalk, setArchiveTechTalk] = useState({
    isOpen: false,
    isActive: false,
    activityId: null,
    title: null,
    isLoading: false,
    filePath: [],
  });
  const [filterData, setFilterData] = useState({
    isloading: true,
    //data being sent to child
    filteredData: [],
    instructorData: [],
    subCategoryNameData: [],
    tagsData: [],
    archiveStatus: [
      { id: 1, status: "Published" },
      { id: 2, status: "Archived" },
      { id: 3, status: "Draft" },
    ],
    //data coming from filter child
    activeFilter: "instructor",
    mentorId: [],
    subCategoryName: [],
    tagId: [],
    getIsArchive: [],
    //searchQuery
    searchQuery: "",
    //pagination
    resultsPerPage: 10,
    currentPage: 1,
  });
  const handleReadMore = (item) => {
    setTechTalkData((prevState) => ({
      ...prevState,
      selectedItems: item,
      showModal: true,
    }));
  };

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleEdit = (Id) => {
    sessionStorage.setItem("techTalkId", Id);
    navigate("/admin/lms/all-techTalk/edit");
  };
  const getAllTechTalk = async () => {
    let url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/getAll`;
    if (filterData.getIsArchive.includes(0) && filterData.getIsArchive.length == 1) {
      url += "?isArchived=0";
    } else if (filterData.getIsArchive.includes(1) && filterData.getIsArchive.length == 1) {
      url += "?isArchived=1";
    } else if (filterData.getIsArchive.includes(2) && filterData.getIsArchive.length == 1) {
      url += "?isArchived=2";
    } else if (filterData.getIsArchive.includes(3) && filterData.getIsArchive.length == 1) {
      url += "?isArchived=3";
    }
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.status == 200) {
        setFilterData({
          ...filterData,
          isloading: false,
          filteredData: response.data.result,
        });
        setTechTalkData({
          ...techTalkData,
          isloading: false,
          originalData: response.data.result,
        });
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  const confirmArchive = async () => {
    setArchiveTechTalk({
      ...archiveTechTalk,
      isLoading: true,
    });
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/archiveById`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      userId: parseInt(parsedObject["decoded"].Id),
      id: archiveTechTalk.activityId,
      isArchive: !archiveTechTalk.isActive,
      filepath: archiveTechTalk.filePath,
    };
    try {
      const response = await axios.post(url, body, { headers });
      if (response.status == 200) {
        setArchiveTechTalk({
          ...archiveTechTalk,
          isOpen: false,
          isLoading: false,
        });
        getAllTechTalk();
        archiveTechTalk.isActive ? toast.success("Tech Talk Archived Successfully !") : toast.success("Tech Talk Restored Successfully !");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  const cancleArchive = () => {
    setArchiveTechTalk({
      isOpen: false,
      isActive: false,
      activityId: null,
      title: null,
      filePath: [],
    });
  };
  const truncate = (str, maxLength) => {
    if (str?.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  };
  const extractData = (jsonData) => {
    const instructorsData = new Set();
    const subCategoryData = new Set();
    const tagsData = new Set();
    if (jsonData) {
      jsonData?.forEach((data) => {
        if (data.instructorId) {
          const instructorData = {
            id: data.instructorId,
            email: data.instructorEmail,
            name: data.instructorName,
          };
          instructorsData.add(JSON.stringify(instructorData));
        }
        const subCategory = {
          categoryId: data.categoryMappingIds,
          categoryName: data.categoryName,
          subCategoryName: data.subCategoryName,
        };
        subCategoryData.add(JSON.stringify(subCategory));
        data?.tags?.forEach((tag) => {
          const tagData = {
            id: tag.id,
            techName: tag.techName,
          };
          tagsData.add(JSON.stringify(tagData));
        });
      });
    }
    const parsedInstructorsData = Array.from(instructorsData).map((data) => JSON.parse(data));
    const parsedSubCategoryData = Array.from(subCategoryData).map((data) => JSON.parse(data));
    const parsedTagsData = Array.from(tagsData).map((data) => JSON.parse(data));
    setFilterData({
      ...filterData,
      instructorData: parsedInstructorsData,
      subCategoryNameData: parsedSubCategoryData,
      tagsData: parsedTagsData,
    });
  };
  const handleMouseEnter = (e, text) => {
    if (text.length > 55) {
      e.target.innerHTML = text;
    }
  };
  const handleFilterChange = (value) => {
    setFilterData((prevState) => ({
      ...prevState,
      searchQuery: value,
    }));

    if ((value && value.length !== 0) || filterData.mentorId.length !== 0 || filterData.subCategoryName.length !== 0 || filterData.tagId.length !== 0) {
      const getFilterByName = (items, searchValue) => {
        if (searchValue) {
          return items?.filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase()));
        }
        return items;
      };
      const getFilterByMentiorId = (items, mentorIds) => {
        if (mentorIds.length != 0) {
          return items.filter((item) => mentorIds.includes(item.instructorId));
        }
        return items;
      };
      const getFilterBySubcategoryName = (items, subCategoryNames) => {
        if (subCategoryNames.length != 0) {
          return items?.filter((item) => subCategoryNames.includes(item.subCategoryName));
        }
        return items;
      };
      const getFilterByTagsId = (items, tagIds) => {
        if (tagIds.length !== 0) {
          return items.filter((item) => item.tags.some((tag) => tagIds.includes(tag.id)));
        }
        return items;
      };
      const filterByName = getFilterByName(techTalkData.originalData, value);
      const filterByMentiorId = getFilterByMentiorId(filterByName, filterData.mentorId);
      const filterBySubcategoryName = getFilterBySubcategoryName(filterByMentiorId, filterData.subCategoryName);
      const filterByTagsId = getFilterByTagsId(filterBySubcategoryName, filterData.tagId);
      setFilterData((prevState) => ({
        ...prevState,
        filteredData: filterByTagsId,
      }));
    } else {
      setFilterData((prevState) => ({
        ...prevState,
        filteredData: techTalkData.originalData,
      }));
    }
  };
  const handleMouseLeave = (e, text) => {
    if (text.length > 55) {
      e.target.innerHTML = truncate(text, 55) + '<span class="text-primary"> Read More</span>';
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}-${date.getFullYear()}`;
    return formattedDate;
  };

  useEffect(() => {
    handleFilterChange();
  }, [techTalkData.isFlterOpen]);

  useEffect(() => {
    getAllTechTalk();
  }, [filterData.getIsArchive]);

  useMemo(() => {
    extractData(techTalkData.originalData);
  }, [techTalkData.originalData]);

  const totalPaginationPages = Math.ceil(techTalkData.originalData?.length / filterData.resultsPerPage);
  const arrayStartIndex = (filterData.currentPage - 1) * filterData.resultsPerPage;
  const arrayEndIndex = parseInt(arrayStartIndex) + parseInt(filterData.resultsPerPage);
  const arrayCurrentResults = filterData.filteredData.slice(arrayStartIndex, arrayEndIndex);

  const handlePageChange = (pageNumber) => {
    setFilterData((prevState) => ({
      ...prevState,
      currentPage: pageNumber,
    }));
    window.scrollTo(0, 0);
  };
  const handlePrevPageChange = () => {
    if (filterData.currentPage != 1) {
      setFilterData((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }));
    }
    window.scrollTo(0, 0);
  };
  const handleNextPageChange = () => {
    if (filterData.currentPage != totalPaginationPages) {
      setFilterData((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }));
    }
    window.scrollTo(0, 0);
  };
  const handleResultsPerPageChange = (event) => {
    setFilterData((prevState) => ({
      ...prevState,
      resultsPerPage: event.target.value,
      currentPage: 1,
    }));
  };
  return (
    <>
      {archiveTechTalk.isOpen && (
        <ArchiveCourse type={"Tech Talk"} taskToArchive={archiveTechTalk} setTaskToArchive={setArchiveTechTalk} onConfirmArchive={confirmArchive} onCancleClick={cancleArchive} />
      )}
      <div style={{ marginBottom: "4rem" }}>
        <Header />
        <SideNav />
      </div>
      <div className="container-fluid tt-table-wrapper ">
        <div className="row">
          <div className="detailrep-header">
            <div className="detailrep-breadcrum">
              <BreadCrumbs />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="total-uploaded-courses-heading p-0">
              <p>Tech Talk</p>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 d-flex justify-content-between align-items-center  ">
            <div className="tt-search-wrapper d-flex border rounded border-2  w-25 px-1">
              <div className="my-1">
                <SearchIcon />
              </div>
              <input
                type="text"
                className="total-uploaded-courses-input-bar py-0 "
                placeholder="Search by Tech Talk Name "
                value={filterData.searchQuery}
                onChange={(e) => {
                  handleFilterChange(e.target.value);
                }}
              />
            </div>
            <div className="total-uploaded-courses-inner-filter">
              <div>
                <button
                  className="fw-bold filter-button"
                  onClick={() => {
                    setTechTalkData({
                      ...techTalkData,
                      isFlterOpen: true,
                    });
                  }}
                >
                  <Filter className="blue-filter-icon" /> Filter
                </button>
              </div>
              {techTalkData.isFlterOpen && (
                <TechTalkFilter
                  closeFilter={setTechTalkData}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  instructorsData={filterData.instructorData}
                  subCategoryData={filterData.subCategoryNameData}
                  tagsData={filterData.tagsData}
                  archiveStatus={filterData.archiveStatus}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="table-scroll dp-scroll pe-0">
            <table className="table tech-talk-table">
              <thead className="ttt-header-wrapper">
                <tr>
                  <th scope="col t-fix" style={{ minWidth: "300px", maxWidth: "300px" }}>
                    Title
                  </th>
                  <th scope="col" style={{ minWidth: "200px", maxWidth: "200px" }}>
                    Category
                  </th>
                  <th scope="col" style={{ minWidth: "200px", maxWidth: "200px" }}>
                    Sub Category
                  </th>
                  <th scope="col" style={{ minWidth: "200px", maxWidth: "200px" }}>
                    Status
                  </th>
                  <th scope="col" style={{ minWidth: "316px", maxWidth: "316px" }}>
                    Description
                  </th>
                  <th scope="col" style={{ minWidth: "222px", maxWidth: "220px" }}>
                    Tags
                  </th>
                  <th scope="col" style={{ minWidth: "150px", maxWidth: "150px" }}>
                    Duration
                  </th>
                  <th scope="col" style={{ minWidth: "150px", maxWidth: "150px" }}>
                    Instructor
                  </th>
                  <th scope="col" style={{ minWidth: "150px", maxWidth: "150px" }}>
                    Uploaded By
                  </th>
                  <th scope="col" style={{ minWidth: "150px", maxWidth: "150px" }}>
                    Uploaded Date
                  </th>
                  <th scope="col" style={{ minWidth: "150px", maxWidth: "150px" }}>
                    Last Modified
                  </th>
                  <th scope="col " style={{ minWidth: "130px", maxWidth: "130px" }}>
                    Viewed By
                  </th>
                  <th scope="col" style={{ minWidth: "130px", maxWidth: "130px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {techTalkData.isloading && <TechTalkSkeltonLoading rowSize={7} className="emptyTable" />}
                {!techTalkData.isloading && filterData.filteredData.length == 0 && (
                  <>
                    <div className="container-fluid my-2 empty-techtalk">
                      <div className="row justify-content-center align-items-center flex-column ">
                        <div className="col-6 text-center">
                          <TechTalkEmptyState />
                        </div>
                        <div className="text-center">
                          <p>No tech talk has been uploaded yet</p>
                          <p
                            className="emprty-link fw-bold "
                            onClick={() => {
                              navigate("/admin/lms/all-techTalk/add");
                            }}
                          >
                            Upload new Tech Talk
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {!techTalkData.isloading &&
                  arrayCurrentResults &&
                  arrayCurrentResults.map((ttData, index) => (
                    <tr key={index} className={`font-14 ${!ttData.isPublished && !ttData.isDraft ? "archived-techtalk text-white" : ""}`}>
                      <td className={`fw-semibold ${!ttData.isPublished && !ttData.isDraft ? "fst-italic" : ""}`}>
                        {truncate(ttData.title, 55)}
                        {ttData.title.length > 55 && (
                          <span
                            className="text-decoration-underline total-uploaded-courses-read-more total-uploaded-courses-cursor-pointer"
                            onClick={() => {
                              handleReadMore(ttData);
                            }}
                          >
                            Read more
                          </span>
                        )}
                      </td>
                      <td>{ttData.categoryName}</td>
                      <td>{ttData.subCategoryName}</td>

                      <td>
                        <div
                          className={`d-flex align-items-center badge total-uploaded-courses-instructor-grade-badge ${ttData.isDraft ? "total-uploaded-courses-instructor-status" : ttData.isPublished ? "total-uploaded-courses-instructor-status-published" : "total-uploaded-courses-instructor-status-active"}`}>
                          {ttData.isDraft ? <AdditionalInfoDraftIcon /> : ttData.isPublished ? <AdditionalInfoPublishedIcon /> : <AdditionalInfoArchivedStatus />}
                          <div>
                            {ttData.isDraft ? "Draft" : ttData.isPublished ? "Published" : "Archived"}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="me-4">
                          {truncate(ttData.description, 55)}{" "}
                          {ttData.description.length > 55 && (
                            <span
                              className="tech-talk-read-more"
                              onClick={() => {
                                handleReadMore(ttData);
                              }}
                            >
                              {" "}
                              Read More
                            </span>
                          )}
                        </div>
                      </td>
                      <td onMouseEnter={() => {
                        setTechTalkData({
                          ...techTalkData,
                          activeRowIndex: index + 1,
                        })
                      }}
                        onMouseLeave={() => {
                          setTechTalkData({
                            ...techTalkData,
                            activeRowIndex: -1,
                          })
                        }}
                        className="d-flex flex-wrap techtalk-tech-tags ">
                        {ttData.tags && ttData.tags.slice(0, 2).map((tag, index) => (
                          <span key={index} className="tt-tags-badge me-1">
                            {tag.techName}&nbsp;
                          </span>
                        ))}
                        {techTalkData.activeRowIndex == index + 1 ?
                          ttData.tags && ttData.tags.slice(2).map((tag, index) => (
                            <span key={index} className="tt-tags-badge me-1">
                              {tag.techName}&nbsp;
                            </span>
                          )) :
                          ttData.tags && ttData.tags.slice(2).length !== 0 && (
                            <span className="tt-tags-count" onMouseEnter={(e) => handleMouseEnter(e, ttData.tags)} onMouseLeave={(e) => handleMouseLeave(e, ttData.tags)}>
                              +{ttData.tags.slice(2).length}
                            </span>
                          )
                        }
                      </td>
                      <td>
                        {ttData.totalVideoCount !== 0 
                          ? ttData.totalVideosLength?.replace(
                              /^\s*(?:(\d+)\s*days?)?\s*(\d+)\s*(?:hrs?|hours?)\s*(\d+)?\s*(?:mins?|minutes?)?\s*$/i,
                              (_, d = 0, h = 0, m = 0) => {
                                const totalHours = d ? Number(d) * 24 + Number(h) : Number(h);
                                return `${totalHours} hr(s) ${m} min(s)`;
                              }
                            )
                          : "NA"}
                      </td>
                      <td>{ttData.instructorId ? ttData.instructorName : "NA"}</td>
                      <td>{ttData.createdByName}</td>
                      <td>{formatDate(ttData.createdDate)}</td>
                      <td>{ttData.modifiedDate ? formatDate(ttData.modifiedDate) : "NA"}</td>
                      <td className="text-secondary fw-bold">
                        <View /> {ttData.viewsCount}
                      </td>
                      <td>
                        <CourseEdit
                          className={`me-2 total-uploaded-courses-cursor-pointer ${ttData.isPublished && "tt-disable-edit"}`}
                          onClick={() => {
                            (ttData.isDraft || !ttData.isPublished) && handleEdit(ttData.id);
                          }}
                        />
                        <span
                          className="total-uploaded-courses-cursor-pointer"
                          onClick={() => {
                            if (!ttData.isDraft) {
                              setArchiveTechTalk({
                                ...archiveTechTalk,
                                isOpen: true,
                                activityId: ttData.id,
                                isActive: ttData.isPublished,
                                title: ttData.title,
                                filePath: ttData.videoDetails && ttData.videoDetails.map((x) => x.filePath),
                              });
                            }
                          }}
                        >
                          {
                            ttData.isDraft ? <AdditionalInfoArchiveIcon className="tt-disable-edit" /> : ttData.isPublished ? <AdditionalInfoArchiveIcon /> : <RestoreIcon className="btn-secondary" />
                          }
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <ReactModal
                size="lg"
                dialogClassName="modal-90w"
                centered
                show={techTalkData.showModal}
                onHide={() =>
                  setTechTalkData((prevState) => ({
                    ...prevState,
                    showModal: false,
                  }))
                }
              >
                <ReactModal.Header
                  closeButton
                  style={{
                    borderBottom: "0.063rem",
                  }}
                >
                  <ReactModal.Title className="fw-bold" style={{ fontSize: "1.1rem" }}>
                    Tech Talk Info
                  </ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                  <p>
                    <span className="fw-bold">Title :</span> <span className="opacity-75">{techTalkData.selectedItems && techTalkData.selectedItems.title}</span>
                  </p>

                  <Form.Group>
                    <Form.Label>
                      <span className="fw-bold">Description : </span>
                    </Form.Label>
                    <Form.Control
                      className="opacity-75"
                      disabled
                      as="textarea"
                      style={{ fontSize: "0.813rem" }}
                      defaultValue={techTalkData.selectedItems && techTalkData.selectedItems.description}
                      rows={4}
                      cols={60}
                    ></Form.Control>
                  </Form.Group>
                </ReactModal.Body>
              </ReactModal>
            </table>
          </div>
              {
                !techTalkData.isloading && arrayCurrentResults.length !== 0 && (
                  <div className="tech-talk-footer border-bottom ">
                    <div>Total Items: {arrayCurrentResults?.length}</div>
                    <div className="d-flex justify-content-end" style={{ color: "#706F73" }}>
                      <div className="d-flex align-items-center">
                        <p className="me-2 mb-0 text-nowrap">Results per page</p>
                        <select style={{ color: "#706F73" }} className="form-select tt-select mx-2 my-0" value={`${filterData.resultsPerPage}`} onChange={handleResultsPerPageChange}>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                        </select>
                        <ul className="pagination mb-0">
                          <li className={`page-item me-1 ${filterData.currentPage === 1 ? "page-item disabled" : ""}`}>
                            <button className="page-link py-0" onClick={handlePrevPageChange}>
                              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1.5L1.5 7L7 12.5" stroke={filterData.currentPage === 1 ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                              </svg>
                            </button>
                          </li>
                          {Array.from({ length: totalPaginationPages }, (_, i) => i + 1).map((page) => (
                            <li key={page} className={`page-item me-1 ${page === filterData.currentPage ? "active" : ""}`}>
                              <button className="page-link rounded pagination-styling  py-0 z-0 " onClick={() => handlePageChange(page)}>
                                {page}
                              </button>
                            </li>
                          ))}
                          <li className={`page-item ${filterData.currentPage === totalPaginationPages ? "page-item disabled" : ""}`}>
                            <button className="page-link  py-0" onClick={handleNextPageChange}>
                              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 12.5L6.5 7L1 1.5" stroke={filterData.currentPage === totalPaginationPages ? "#A4A3A7" : "#28519E"} strokeWidth="1.5" />
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )
              }
        </div>
      </div >
    </>
  );
};

export default TechtalkTable;
