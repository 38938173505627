import { useState, useEffect, useRef, CloudImage, Files, Video, Drag, Edit, Delete, Plus, BluePlus, Quiz, CourseQuiz, SizeLimit, AdditionalInfoPptIcon, CustomModal, AlertInfo, Preview, PreviewModal, Papa, DownloadIcon, ColonImage, AttachmentClock } from "../UploadCoursesImport";
import "./UploadContent.css";

const UploadContent = (props) => {
  const { courseUploadData, setCourseUploadData, error, setError, editMode } = props;
  const [editingModuleIndex, setEditingModuleIndex] = useState(0);
  const [ratingData, setRatingData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const [previewModal, setPreviewModal] = useState({
    previewModalOpen: false,
    selectedPreviewFile: null,
  });
  const [moduleCompletionTime, setModuleCompletionTime] = useState("");

  const fileInputRef = useRef([]);
  const quizFileInputRef = useRef();

  const addModule = (e) => {
    e.preventDefault();
    const newModule = {
      selectedFiles: [],
      moduleName: "",
      quizFile: null,
      isAdded: true,
      isDeleted: false,
      isSaved: false,
    };

    const deletedModuleIndex = courseUploadData.modules.findIndex((module) => module.isDeleted);

    const insertIndex = deletedModuleIndex !== -1 ? deletedModuleIndex : courseUploadData.modules.length;

    setCourseUploadData((prevData) => ({
      ...prevData,
      modules: [...prevData.modules.slice(0, insertIndex), newModule, ...prevData.modules.slice(insertIndex)],
    }));

    setEditingModuleIndexFunction(insertIndex);
  };

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  const removeModule = (index) => {
    setCourseUploadData((prevData) => {
      const updatedModules = [...prevData.modules];
      let deletedModule = null;
      if (editMode) {
        if (updatedModules[index].isAdded) {
          deletedModule = updatedModules.splice(index, 1)[0];
        } else {
          deletedModule = updatedModules.splice(index, 1)[0];
          deletedModule.isDeleted = true;
          updatedModules.push(deletedModule);
        }
      } else {
        deletedModule = updatedModules.splice(index, 1)[0];
      }

      const nonEmptyModules = updatedModules.filter((module) => module.moduleName.trim() !== "" || module.selectedFiles.length > 0);

      if (index === editingModuleIndex) {
        setEditingModuleIndex(null);
      }

      setError((prevError) => ({
        ...prevError,
        moduleNameError: "",
      }));

      return {
        ...prevData,
        modules: nonEmptyModules,
      };
    });
    calculateTotalModuleTime();
  };

  const setEditingModuleIndexFunction = (index) => {
    const isNewModuleEmpty = courseUploadData.modules.some((module, i) => i !== index && module.moduleName === "");

    if (isNewModuleEmpty) {
      setCourseUploadData((prevData) => {
        const updatedModules = prevData.modules.filter((module) => module.moduleName !== "" || module.selectedFiles.length > 0);

        return {
          ...prevData,
          modules: updatedModules,
        };
      });
    }
    setEditingModuleIndex(index);
  };

  const handleChangeName = (e, index) => {
    const inputName = e.target.value;
    const specialCharsRegex = /^(?!.*--)(?!.*::)(?!.*(?:\s{3,}))[a-zA-Z0-9\s\-:.]{1,254}$/;
    const activeModules = courseUploadData.modules.filter((module) => !module.isDeleted); // Filter out deleted modules
    const isDuplicate = activeModules.some((module, i) => i !== index && module.moduleName.trim().toLowerCase() === inputName.trim().toLowerCase());
    const containsLetter = /[a-zA-Z0-9]/.test(inputName);
    if (!specialCharsRegex.test(inputName) || !containsLetter) {
      setError((prevData) => ({
        ...prevData,
        moduleIndex: index,
        moduleNameError: "Only alphabets, colon, hyphen and comma allowed ranging between 1 to 255 characters",
      }));
    } else if (isDuplicate) {
      setError((prevData) => ({
        ...prevData,
        moduleIndex: index,
        moduleNameError: "Module name already exists. Please enter a unique name.",
      }));
    } else {
      setError((prevData) => ({
        ...prevData,
        moduleIndex: null,
        moduleNameError: "",
      }));
    }
    setCourseUploadData((prevData) => {
      const updatedModules = [...prevData.modules];
      updatedModules[index] = {
        ...updatedModules[index],
        moduleName: e.target.value,
      };
      return {
        ...prevData,
        modules: updatedModules,
      };
    });
  };

  const handleSaveClick = async (e, index) => {
    e.preventDefault();
    setEditingModuleIndex(null);
    setCourseUploadData((prevData) => {
      const updatedModules = [...prevData.modules];
      updatedModules[index].isSaved = true;
      return {
        ...prevData,
        modules: updatedModules,
      };
    });
    calculateTotalModuleTime();
  };

  const countFilesInModules = () => {
    let totalMP4 = 0;
    let totalPdf = 0;
    let totalPPT = 0;

    courseUploadData?.modules?.forEach((module) => {
      module?.selectedFiles?.forEach((file) => {
        switch (file.file.type) {
          case "video/mp4":
            totalMP4++;
            break;
          case "application/pdf":
            totalPdf++;
            break;
          case "application/vnd.ms-powerpoint":
          case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            totalPPT++;
            break;
          default:
            break;
        }
      });
    });
    return { totalMP4, totalPdf, totalPPT };
  };
  const countExistingFiles = () => {
    let totalMP4 = 0;
    let totalPdf = 0;
    let totalPPT = 0;

    courseUploadData?.modules?.forEach((module) => {
      module?.selectedFiles?.forEach((file) => {
        const extension = file?.file.name?.split(".").pop().toLowerCase(); // Get the file extension
        if (!file.isDeleted) {
          if (extension === "pdf") {
            totalPdf++;
          } else if (extension === "ppt" || extension === "pptx") {
            totalPPT++;
          } else if (extension === "mp4" || extension === "avi" || extension === "mov") {
            totalMP4++;
          }
        }
      });
    });
    return { totalMP4, totalPdf, totalPPT };
  };
  const updateFileCounts = () => {
    const { totalMP4, totalPdf, totalPPT } = editMode ? countExistingFiles() : countFilesInModules();
      setCourseUploadData((prevData) => ({
        ...prevData,
        totalVideos: totalMP4,
        videoHrs: totalMP4 === 0 ? "0" : prevData.videoHrs,
        videoMins: totalMP4 === 0 ? "0" : prevData.videoMins,
        totalPdf: totalPdf,
        pdfPages: totalPdf === 0 ? "0" : prevData.pdfPages,
        totalPPT: totalPPT,
        PPTPages: totalPPT === 0 ? "0" : prevData.PPTPages,
      }));
  };
  const handleFileChange = (files, targetModuleIndex) => {
    const allowedFileTypes = ["application/pdf", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "video/mp4"];
    const maxFileSizeInBytes = 1024 * 1024 * 1024; // 1GB
    const maxTotalSizeInBytes = 1024 * 1024 * 1024; // 1GB

    const invalidFiles = Array.from(files).filter((file) => {
      // Check if file type is allowed
      if (!allowedFileTypes.includes(file.type)) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Please select PDF, PPT, or MP4 files only",
          icon: <AlertInfo />,
        }));
        return true;
      }
      // Check if file size exceeds the limit
      if (file.size >= maxFileSizeInBytes) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "File size should be less than 1GB",
          icon: <AlertInfo />,
        }));
        return true;
      }
      return false;
    });

    if (invalidFiles.length > 0) {
      return;
    }
    const filteredFiles = Array.from(files).filter((file) => allowedFileTypes.includes(file.type));

    const fileWithSpaces = filteredFiles.find((file) => file.name.includes(" "));
    if (fileWithSpaces) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "File name should not contain spaces",
        icon: <AlertInfo />,
      }));
      return;
    }
    setCourseUploadData((prevData) => {
      const totalExistingSize = prevData.modules.reduce((acc, module) => {
        return acc + module.selectedFiles.filter((file) => file.isAdded && !file.isDeleted).reduce((moduleAcc, file) => moduleAcc + file.file.size, 0);
      }, 0);

      const totalNewSize = filteredFiles.reduce((acc, file) => acc + file.size, 0);

      if (totalExistingSize + totalNewSize > maxTotalSizeInBytes) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Total file size should not exceed 1GB",
          icon: <AlertInfo />,
        }));
        return prevData; // Return early if the size limit is exceeded
      }

      const isDuplicateAcrossModules = prevData.modules.some((module) => module.selectedFiles.some((existingFile) => !existingFile.isDeleted && filteredFiles.some((newFile) => existingFile.file.name === newFile.name)));
      if (isDuplicateAcrossModules) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Files with the same names already exist. Please choose unique files.",
          icon: <AlertInfo />,
        }));
        return prevData;
      }

      const updatedModules = prevData.modules.map((module, index) => {
        if (index === targetModuleIndex) {
          const deletedFiles = module.selectedFiles.filter((existingFile) => existingFile.isDeleted);

          const updatedSelectedFiles = module.selectedFiles.filter((existingFile) => !existingFile.isDeleted);

          // Add isAdded and isDeleted properties to each file object
          const newFiles = filteredFiles.map((file) => ({
            file: file,
            isAdded: true,
            isDeleted: false,
          }));

          return {
            ...module,
            selectedFiles: [...updatedSelectedFiles, ...newFiles, ...deletedFiles],
            isSaved: false,
          };
        }
        return module;
      });

      return {
        ...prevData,
        modules: updatedModules,
      };
    });

    filteredFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        if (file.type === "video/mp4") {
          const video = document.createElement("video");
          video.onloadedmetadata = () => {
            //For Calculating video duration
            const videoDuration = video.duration;
          };
          video.src = URL.createObjectURL(file);
        }
      };
      reader.onerror = (e) => {
        console.error("Error reading the file:", e.target.error);
      };
      reader.readAsText(file);
      return reader;
    });
  };

  const handleRemoveFile = (fileIndex, moduleIndex) => {
    fileInputRef.current.value = "";
    setCourseUploadData((prevData) => {
      const updatedModules = [...prevData.modules];
      const selectedFiles = [...updatedModules[moduleIndex]?.selectedFiles];
      if (editMode) {
        if (selectedFiles[fileIndex].isAdded) {
          selectedFiles.splice(fileIndex, 1);
        } else {
          const [removedFile] = selectedFiles.splice(fileIndex, 1);
          removedFile.isDeleted = true;
          selectedFiles.push(removedFile);
          updatedModules[moduleIndex].isSaved = false;
        }
      } else {
        // If not in edit mode, remove the file from the selectedFiles array
        selectedFiles.splice(fileIndex, 1);
        updatedModules[moduleIndex].isSaved = false;
      }
      updatedModules[moduleIndex].selectedFiles = selectedFiles;
      return {
        ...prevData,
        modules: updatedModules,
      };
    });
  };

  const calculateTotalModuleTime = () => {
    console.log("Inisde the funciton")
    const { modules } = courseUploadData;
    let totalMinutes = 0;
  
    modules.forEach(module => {
      const hours = parseInt(module.moduleHrs) || 0;
      const minutes = parseInt(module.moduleMin) || 0;
      totalMinutes += (hours * 60) + minutes; 
    });
  
    const totalHours = Math.floor(totalMinutes / 60); 
    const remainingMinutes = totalMinutes % 60;
    const totalModuleCompletionTime = `${totalHours} hrs ${remainingMinutes} mins`;
    console.log(totalModuleCompletionTime, "Module time")
    setCourseUploadData((prevData) => ({
      ...prevData,
      courseHrs: totalHours,
      courseMin: remainingMinutes,
      totalCourseCompletionTime: totalModuleCompletionTime
    }));
  };  

  useEffect(() => {
    updateFileCounts();
    calculateTotalModuleTime();
  }, [courseUploadData.modules]);

  useEffect(() => {
    // Check if edit mode is true and there's only one module with null module name
    if (editMode && courseUploadData.modules.length === 1 && courseUploadData.modules[0].moduleName === "") {
      setCourseUploadData((prevData) => ({
        ...prevData,
        modules: [
          {
            ...prevData.modules[0],
            isAdded: true,
          },
        ],
      }));
    }
  }, [editMode]);

  const handleBrowseClick = () => {
    fileInputRef?.current.click();
  };
  const handleQuizBrowseClick = () => {
    quizFileInputRef?.current.click();
  };
  const getFileIcon = (fileName) => {
    const extension = fileName?.split(".").pop().toLowerCase();
    switch (extension) {
      case "mp4":
        return <Video />;
      case "pptx":
        return <AdditionalInfoPptIcon />;
      default:
        return <Files />;
    }
  };
  const handleDrop = (e, index) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    handleFileChange(droppedFiles, index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, fileIndex, moduleIndex) => {
    e.dataTransfer.setData("fileIndex", fileIndex);
    e.dataTransfer.setData("moduleIndex", moduleIndex);
  };

  const handleDropFile = (e, currentIndex, moduleIndex) => {
    e.preventDefault();
    const prevFileIndex = e.dataTransfer.getData("fileIndex");
    const prevModuleIndex = e.dataTransfer.getData("moduleIndex");

    const fileIdx = parseInt(prevFileIndex);
    const moduleIdx = parseInt(prevModuleIndex);

    if (moduleIndex === moduleIdx) {
      setCourseUploadData((prevData) => {
        const updatedModules = prevData.modules.map((module, index) => {
          if (index === moduleIdx) {
            const draggedFile = module.selectedFiles[fileIdx];
            const updatedSelectedFiles = [...module.selectedFiles];

            updatedSelectedFiles.splice(fileIdx, 1);

            let adjustedIndex = currentIndex;

            if (currentIndex > fileIdx) {
              adjustedIndex = currentIndex;
            } else if (currentIndex < fileIdx) {
              adjustedIndex = currentIndex;
            }

            updatedSelectedFiles.splice(adjustedIndex, 0, draggedFile);

            return {
              ...module,
              selectedFiles: updatedSelectedFiles,
            };
          }
          return module;
        });

        return {
          ...prevData,
          modules: updatedModules,
        };
      });
    }
  };

  const handleDragOverFile = (e) => {
    e.preventDefault();
  };

  const handleModuleDragStart = (e, moduleIndex) => {
    e.dataTransfer.setData("moduleIndex", moduleIndex);
  };

  const handleDropModule = (e, currentIndex) => {
    e.preventDefault();
    const prevModuleIndex = e.dataTransfer.getData("moduleIndex");
    const moduleIdx = parseInt(prevModuleIndex);

    if (currentIndex !== moduleIdx) {
      setCourseUploadData((prevData) => {
        const updatedModules = [...prevData.modules];
        const draggedModule = updatedModules.splice(moduleIdx, 1)[0];
        updatedModules.splice(currentIndex, 0, draggedModule);

        return {
          ...prevData,
          modules: updatedModules,
        };
      });
    }
  };
  const parseCSV = (file, isAdded) => {
    Papa.parse(file, {
      complete: (result) => {
        if (isAdded) {
          setCourseUploadData((prevData) => ({
            ...prevData,
            quizQuestions: courseUploadData.quizQuestions + (result.data.length - 2),
          }));
        } else {
          setCourseUploadData((prevData) => ({
            ...prevData,
            quizQuestions: courseUploadData.quizQuestions - (result.data.length - 2),
          }));
        }
      },
    });
  };
  const handleCSVFileChange = (file, targetModuleIndex) => {
    const allowedFileType = "text/csv";

    if (file[0].type !== allowedFileType) {
      setRatingData((prevData) => ({
        ...prevData,
        isModalOpen: true,
        modalType: "error",
        modalTitle: "Error !",
        modalMessage: "Please select a CSV file only",
        icon: <AlertInfo />,
      }));
      return;
    }

    setCourseUploadData((prevData) => {
      const isDuplicateAcrossModules = prevData.modules.some((module) => module.quizFile && !module.quizFile.isDeleted && module.quizFile.file.name === file[0].name);

      if (isDuplicateAcrossModules) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "A quiz file with the same name already exists in another module. Please choose a unique file.",
          icon: <AlertInfo />,
        }));
        return prevData;
      }

      const updatedModules = prevData.modules.map((module, index) => {
        if (index === targetModuleIndex) {
          if (module.quizFile && module.quizFile.isDeleted) {
            module.selectedFiles.push({
              file: module.quizFile.file,
              isDeleted: true,
              isQuiz: true,
            });
            module.quizFile = null;
          }
          return {
            ...module,
            quizFile: {
              file: file[0],
              isAdded: true,
              isDeleted: false,
            },
            isSaved: false,
          };
        }
        return module;
      });

      parseCSV(file[0], true);
      return {
        ...prevData,
        modules: updatedModules,
      };
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "." || event.key === "-" || event.key.toLowerCase() === "e") {
      event.preventDefault();
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const { modules } = courseUploadData;
    let validatedValue = value;

    const parsedValue = parseInt(value);
    const isInvalid = isNaN(parsedValue)&& value !== "";

    switch (name) {
      case "moduleHrs":
        if (isInvalid || parsedValue <= 0 || parsedValue > 23) {
          if (value !== "" && parsedValue !== 0) {
          setRatingData((prevData) => ({
            ...prevData,
            isModalOpen: true,
            modalType: "error",
            modalTitle: "Error !",
            modalMessage: "Max Value for hours is 23",
            icon: <AlertInfo/>,
          }));
        }
          validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 23);
        }
        break;
      case "moduleMin":
        if (isInvalid || parsedValue <= 0 || parsedValue > 59) {
          if (value !== "" && parsedValue !== 0) {
          setRatingData((prevData) => ({
            ...prevData,
            isModalOpen: true,
            modalType: "error",
            modalTitle: "Error !",
            modalMessage: "Max Value for minutes is 59",
            icon: <AlertInfo/>,
          }));
        }
          validatedValue = Math.min(Math.max(parsedValue || 0, 0), 59);
        }
        break;
      default:
        break;
    }
    const updatedModules = [...modules];
    updatedModules[index] = {
      ...updatedModules[index],
      [name]: validatedValue, 
    };

    setCourseUploadData((prevData) => ({
      ...prevData,
      modules: updatedModules,
    }));
  };

  const handleRemoveQuizFile = (moduleIndex) => {
    setCourseUploadData((prevData) => {
      const updatedModules = [...prevData.modules];
      const removedFileNumberOfQuestions = updatedModules[moduleIndex].quizFile.file.numberOfQuestions || 0; // Get numberOfQuestions of the removed file
      const currentQuizQuestions = prevData.quizQuestions || 0;
      const newQuizQuestions = Math.max(0, currentQuizQuestions - removedFileNumberOfQuestions); // Ensure the result is not negative

      if (editMode) {
        if (updatedModules[moduleIndex].quizFile.isAdded) {
          parseCSV(updatedModules[moduleIndex].quizFile.file, false);
          updatedModules[moduleIndex] = {
            ...updatedModules[moduleIndex],
            quizFile: null,
          };
        } else {
          updatedModules[moduleIndex].quizFile.isDeleted = true;
        }
        updatedModules[moduleIndex].isSaved = false;
      } else {
        // If not in edit mode, remove the quizFile from the module
        parseCSV(updatedModules[moduleIndex].quizFile.file, false);
        updatedModules[moduleIndex] = {
          ...updatedModules[moduleIndex],
          quizFile: null,
        };
        updatedModules[moduleIndex].isSaved = false;
      }
      return {
        ...prevData,
        modules: updatedModules,
        quizQuestions: newQuizQuestions,
      };
    });
  };

  const openPreviewModal = (file) => {
    setPreviewModal(() => ({
      previewModalOpen: true,
      selectedPreviewFile: file,
    }));
  };

  return (
    <>
      <div className="container">
        <div className="module-text mb-1">Add Module</div>
        {courseUploadData?.modules
          ?.filter((module) => !module.isDeleted)
          .map((module, index) => (
            <>
              <div className="row border mb-3" key={index}>
                {editingModuleIndex === index ? (
                  <>
                    <div className="col-3">
                      <div className="upload-modules-csv align-items-center justify-content-center mt-3 mb-2" onDrop={(e) => handleDrop(e, index)} onDragOver={(e) => handleDragOver(e)}>
                        <div className=" mt-2">
                          <CloudImage />
                        </div>
                        <div className="mb-2">
                          <span className="drag-drop-text d-flex justify-content-center fw-bold">Drag and drop here</span>
                        </div>
                        <div className="mb-2">
                          <div>
                            <div>
                              <input className="d-none" type="file" ref={fileInputRef} onChange={(e) => {handleFileChange(e.target.files, index);e.target.value = ""}} accept=".pdf,.ppt,.pptx,.mp4" multiple />
                              <button type="button" onClick={handleBrowseClick} className="modle-file-upload-button">
                                Select File
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-2">
                          <div className="file-size-limit gap-2">
                            <SizeLimit />
                            <p className="limit-text">File Size Limit 400 MB</p>
                          </div>
                        </div>
                      </div>
                      <p className="thumbnail-text">File Supported: MP4, PDF and PPTX</p>
                    </div>

                    <div className="col-9">
                      <div className="mb-3 mt-2">
                        <label className="col-form-label form-title-names">
                          Module Name<span className="text-danger">*</span>
                        </label>
                        <input type="text" className="form-control" placeholder="Enter Module Name" onChange={(e) => handleChangeName(e, index)} value={module.moduleName} />
                        {error.moduleIndex === index && module.moduleName && error?.moduleNameError !== "" && <span className="text-danger error-msg">{error?.moduleNameError}</span>}
                      </div>

                      {module?.selectedFiles
                        ?.filter((file) => !file.isDeleted)
                        .map((file, fileIndex) => (
                          <div key={fileIndex}>
                            <div className="d-flex align-items-center ps-1 ast-search-wrapper">
                              <div className="progress-indicator-status">
                                <div className="d-flex align-items-center selected-module-files">
                                  <div key={fileIndex} draggable="true" onDragStart={(e) => handleDragStart(e, fileIndex, index)} onDrop={(e) => handleDropFile(e, fileIndex, index)} onDragOver={handleDragOverFile} className="mx-2">
                                    <Drag />
                                  </div>
                                  {getFileIcon(file?.file?.name)}
                                  <div className="mx-1" data-toggle="tooltip" data-placement="top" title={file?.file?.name}>
                                    {file?.file?.name.length > 50 ? `${file?.file?.name.substring(0, 40)}...` : file?.file?.name}
                                  </div>
                                </div>
                              </div>
                              {file?.file?.name?.split(".").pop().toLowerCase() !== "pptx" && <div className="mx-2" onClick={() => openPreviewModal(file)}>
                                <Preview />
                              </div>}
                              <div
                                onClick={() => {
                                  handleRemoveFile(fileIndex, index);
                                }}
                              >
                                <Delete />
                              </div>
                            </div>
                          </div>
                        ))}
                      {module?.quizFile !== null && !module.quizFile.isDeleted && (
                        <div className="d-flex align-items-center ps-1 ast-search-wrapper">
                          <div className="progress-indicator-status">
                            <div className="d-flex align-items-center selected-module-files">
                              <div className="mx-2">
                                <Drag />
                              </div>
                              <CourseQuiz />
                              <div className="mx-1">{module?.quizFile?.file.name}</div>
                            </div>
                          </div>
                          <div className="mx-2" onClick={() => openPreviewModal(module?.quizFile)}>
                            <Preview />
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveQuizFile(index);
                            }}
                          >
                            <Delete />
                          </div>
                        </div>
                      )}
                      {(module?.quizFile === null || module?.quizFile?.isDeleted) && (
                        <div className="mt-3 mb-3">
                          <div className="d-flex align-items-center">
                          <input className="d-none" type="file" ref={quizFileInputRef} onChange={(e) => handleCSVFileChange(e.target.files, index)} accept=".csv" />
                          <button type="button" className="btn btn-outline-custom" onClick={handleQuizBrowseClick}>
                            <BluePlus />
                            <span className="add-csv-text mx-2">Add Quiz (CSV Format)</span>
                          </button>
                           <div className="ms-2">
                          <div className="file-size-limit-csv gap-2">
                            <SizeLimit className="mt-2"/>
                            <p className="limit-text">Please do not use comma, inverted comma or double inverted comma in CSV file</p>
                          </div>
                        </div>
                        </div>
                          <div className="d-flex justify-content-between sample-csv mt-2">
                            <span>Download Sample CSV Template</span>
                            <a href={process.env.REACT_APP_SAMPLE_QUIZ} className="fw-bold"><DownloadIcon /> Sample Quiz</a>
                          </div>
                        </div>
                      )}
                      <div className="mb-3">
              <div className="d-flex align-items-center mb-1">
                <AttachmentClock className="d-flex align-items-center" />
                <label htmlFor="sideBySideInputs" className="ms-1 assignment-sub-heading">
                  Module Completion Time<span className="text-danger">*</span>
                </label>
              </div>
              <div className="row g-1 completion-time-div">
                {/* <div className="col-3">
                  <input type="number" min="0" className="form-control completion-time-input" placeholder="day(s)" name="assignmentDay" onKeyDown={(event) => handleKeyDown(event)} 
                  onChange={(e) => handleInputChange(e)} value={module.moduleDay}
                  // onChange={(e) => handleInputChange(e)} value={courseUploadData.assignmentDay} 
                  />
                </div>
                <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                  <ColonImage />
                </div> */}
                <div className="col-3">
                  <input type="number" min="0" className="form-control completion-time-input" placeholder="hr(s)" name="moduleHrs" onKeyDown={(event) => handleKeyDown(event)} 
                  onChange={(e) => handleInputChange(e, index)} value={module.moduleHrs}
                  // onChange={(e) => handleInputChange(e)} value={courseUploadData.assignmentHrs} 
                  />
                </div>
                <div className="col-1 p-0 d-flex align-items-center justify-content-center">
                  <ColonImage />
                </div>
                <div className="col-3">
                  <input type="number" min="0" className="form-control completion-time-input" placeholder="min(s)" name="moduleMin" onKeyDown={(event) => handleKeyDown(event)} 
                  onChange={(e) => handleInputChange(e, index)} value={module.moduleMin}
                  // onChange={(e) => handleInputChange(e)} value={courseUploadData.assignmentMin}
                   />
                </div>
              </div>
            </div>
                      <div className="mt-3 mb-3">
                        <button type="button" className="btn module-save-button" disabled={!module.moduleName || error.moduleIndex === index || module.isDeleted || module.selectedFiles?.filter((file) => !file.isDeleted).length === 0 ||((module.moduleHrs===0 || module.moduleHrs==="")  && (module.moduleMin===0 || module.moduleMin==="")) || error?.moduleNameError !== ""} onClick={(e) => handleSaveClick(e, index)}>
                          <span className="save-text">Save</span>
                        </button>
                        <button type="button" className="btn module-delete-button mx-3" onClick={() => removeModule(index)}>
                          <span className="delete-text">Delete</span>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="col-12 p-2 d-flex justify-content-between module-head-wrapper">
                    <div className="d-flex align-items-center">
                      <div key={index} draggable="true" onDragStart={(e) => handleModuleDragStart(e, index)} onDrop={(e) => handleDropModule(e, index)} onDragOver={(e) => e.preventDefault()} className="mx-2 mb-1">
                        <Drag />
                      </div>

                      <div className="d-flex align-items-center">{module.moduleName}</div>
                    </div>
                    <div className="mx-2 d-flex">
                      <Edit className="mx-2" onClick={() => setEditingModuleIndexFunction(index)} />
                      <Delete onClick={() => removeModule(index)} />
                    </div>
                  </div>
                )}
              </div>
            </>
          ))}
        <div className="row mt-3">
          <div className="col-4">
            <button type="button" className="btn module-add-button" onClick={(e) => addModule(e)}>
              <Plus />
              <span className="save-text mx-2">{courseUploadData.modules.length === 0 ? "Add Module" : "Add Another Module"}</span>
            </button>
          </div>
        </div>
        {ratingData.isModalOpen && (
          <CustomModal
            modalProps={{
              icon: ratingData.icon,
              show: ratingData.isModalOpen,
              type: ratingData.modalType,
              title: ratingData.modalTitle,
              message: ratingData.modalMessage,
              onCancel: handleClose,
            }}
          />
        )}
        <PreviewModal
          editMode={editMode}
          file={previewModal.selectedPreviewFile}
          isOpen={previewModal.previewModalOpen}
          onClose={() =>
            setPreviewModal(() => ({
              selectedPreviewFile: null,
              previewModalOpen: false,
            }))
          }
        />
      </div>
      <div className="mt-3">Total Module Completion Time: {courseUploadData.totalCourseCompletionTime}</div>
    </>
  );
};

export default UploadContent;
