import { TotalCourses, ArrowForwardIcon, useNavigate } from "../../AdminImport";

const TotalCoursesFile = ({ totalCourses }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/admin/lms/total-uploaded-courses");
  };
  return (
    <div className="col-12 card total-courses-card mb-3">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex pointer" onClick={handleClick}>
            <p className="mb-0 me-1 total-courses-head">Courses Library</p>
            <span className="d-flex align-items-center">
              <ArrowForwardIcon />
            </span>
          </div>
          <div>
            <img src={TotalCourses} alt="TotalCourses" />
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between">
          <div className="mb-0 ">
            <p className="total-courses-head mb-1 d-flex justify-content-center">
              {totalCourses?.videoCount}
            </p>
            <p className="course-content-text mb-0 d-flex justify-content-center">
              Videos
            </p>
          </div>
          <div className="mb-0 ">
            <p className="total-courses-head mb-1 d-flex justify-content-center">
              {totalCourses?.pptCount}
            </p>
            <p className="course-content-text mb-0 d-flex justify-content-center">
              Powerpoints
            </p>
          </div>
          <div className="mb-0 ">
            <p className="total-courses-head mb-1 d-flex justify-content-center">
              {totalCourses?.pdfCount}
            </p>
            <p className="course-content-text mb-0 d-flex justify-content-center">
              E-Books
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCoursesFile;
