import React, { useEffect, useState, useContext, useMemo, useRef} from "react";
import { UserContext } from "../../Context/Context";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as CGLogo } from "../../Assets/CG Logo Updated.svg";

import { ReactComponent as ExpandMore } from "../../Assets/expand_more.svg";
import { ReactComponent as RightArrow } from "../../Assets/arrow_forward_iosarrow.svg";
import { ReactComponent as SignOut } from "../../Assets/SignOut.svg";
import "bootstrap/dist/css/bootstrap.css";
import decryptData from "../_common/DecryptUserData";
import { handleApiError } from "../_common/HandleApiError";
import MentorAssignedAlerts from "../UserPortal/Dashboard/MentorAssignedAlerts/MentorAssignedAlerts";
import "../AdminPortal/Dashboard/UploadCsv/uploadCsv.css";
import axios from "axios";
import BatchSelect from "./BatchSelect";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as VideoPlay } from "../../Assets/headerTechTalk.svg";
import { ReactComponent as UploadCourse } from "../../Assets/uploadheaderCourse.svg";
import "./Header.css";
import CourseCategoriesDropdown from "./CourseCategoriesDropdown";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Cookies } from "react-cookie";

const Header = (props) => {
  const [courseCategoryDropdown, setCourseCategoryDropdown] = useState(false);
  const [batchDropdown, setBatchDropdown] = useState(false);
  const [menu, setMenu] = useState(true);
  const [batches, setBatches] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { resetTimer } = useContext(UserContext);
  const [userData, setUserData] = useState({});
  const [isLogout, seIsLogOut] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [profilePic, setProfilePic] = useState(undefined);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  let permission = new Set();

  const handleSuggestionClick = (subCategoryId) => {
    sessionStorage.setItem("subCategoryName", subCategoryId?.name);
    navigate(`/lms/course-by-category/${subCategoryId?.id}`);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCourseCategoryDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleSearch = async (event) => {
    if (!event) {
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_DOTNET}/Category/getAllCategoryAndSubCategory`, {
        headers: {
          Authorization: `Bearer ${parsedObject["token"]}`,
        },
      });

      if (response.data.success === 200) {
        const categories = response.data.result;
        const allSubCategories = categories.reduce((acc, category) => {
          if (category.subCategories && Array.isArray(category.subCategories)) {
            acc.push(...category.subCategories);
          }
          return acc;
        }, []);
        setSuggestions(allSubCategories);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };
  const cookies = new Cookies();
  const getProfilePicture = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_DOTNET}/Sso/image?imageName=${parsedObject.decoded["ImageGuid"]}`, {
        headers: { Authorization: `Bearer ${parsedObject["token"]}` },
        responseType: "blob"
      });
      if (response.status == 200) {
        const blob = response.data;
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64Image = reader.result;
          localStorage.setItem('_profileImage', base64Image);
          setProfilePic(base64Image);
        };
        reader.readAsDataURL(blob);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (localStorage.getItem("_profileImage") == undefined)
      getProfilePicture();
    else
      setProfilePic(localStorage.getItem('_profileImage'))
  }, [])

  useEffect(() => {
    if (cookies.get("_profile") == undefined) {
      cookies.set("_profile", profilePic);
    } else {
      cookies.update("_profile", profilePic);
    }
  }, [profilePic])

  useEffect(() => {
    const data = localStorage.getItem("userData");
    data ? setUserData(decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  }, []);

  const handleLogout = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Authentication/logoff`;
    const headers = {
      Authorization: `Bearer ${userData.token}`,
    };

    try {
      const response = await axios.post(url, {}, { headers });
      if (response.status == 200) {
        localStorage.clear("userData");
        localStorage.clear("tD8kFi5j");
        sessionStorage.clear("Batches");
        const cookieName = process.env.REACT_APP_API_CGU_CGONE_TOKEN_NAME
        cookies.update(cookieName, undefined, { path: "/", domain: ".cginfinity.com" });
        resetTimer();
        navigate("/");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response.status : null;
      handleApiError(statusCode, navigate);
    }
  };

  if (userData?.permissionTypeName !== undefined) {
    const arr = userData?.permissionTypeName?.split(",");
    arr?.forEach((element) => {
      permission.add(`${element}`);
    });
  }
  const batchDataComingFrmChild = (data) => {
    setBatches(data);
  };

  return (
    <>
      <div style={{ position: "fixed", top: "0", zIndex: "99", width: "100%" }}>
        <nav className="navbar navbar-expand-lg navbar-light  border-bottom border-xs-0" style={{ backgroundColor: "#FFFFFF" }}>
          <NavLink className="navbar-brand" to={userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_ADMIN ? "/admin/lms/dashboard" : "/lms/dashboard"} style={{ marginLeft: "10px", marginRight: "0px" }}>
            <div className="d-flex flex-column border-right logo-right">
              <CGLogo />
            </div>
          </NavLink>
          <div className={`collapse navbar-collapse border-Side ${menu ? "" : "show"}`} id="navbarNav">
            {location.pathname.includes("/lms/") && (
              <>
                <div className="mx-5">
                  <button
                    className="categories-select-wrapper d-flex align-items-center gap-2"
                    onClick={() => {
                      setCourseCategoryDropdown((prev) => !prev); // Toggle dropdown state
                    }}
                  >
                    <p className="m-0 fw-bold">Course Categories</p>
                    <p className="m-0">
                      {" "}
                      <ExpandMore />
                    </p>
                  </button>
                </div>
                <div>
                  <ReactSearchAutocomplete className="header-search-bar"
                    items={suggestions} onSearch={(event) => handleSearch(event)}
                    onSelect={(event) => handleSuggestionClick(event)} placeholder="Search" />
                </div>
                <div
                  ref={dropdownRef}
                  className="header-category-dropdown-styling"
                  style={{
                    display: courseCategoryDropdown ? "" : "none",
                  }}
                >
                  <CourseCategoriesDropdown setCourseCategoryDropdown={setCourseCategoryDropdown} />
                </div>
              </>
            )}
          </div>
          {userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_ADMIN && location.pathname.includes("/admin/lms/") && (
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle id="upload-course-tech-talk" style={{ marginRight: "1.5rem", backgroundColor: "#28519E", border: "none" }}>
                Upload
              </Dropdown.Toggle>

              <Dropdown.Menu className="upload-tt-c">
                <Dropdown.Item
                  onClick={() => {
                    navigate("/admin/lms/addCourse");
                  }}
                >
                  <div className="d-flex gap-2">
                    <div>
                      <UploadCourse style={{ color: "red" }} />
                    </div>
                    <div className="m-0 drop-txt-wrapper">
                      <p className="m-0">Upload Course</p>
                      <p className="m-0">For New Course Material</p>
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    sessionStorage.clear("techTalkId");
                    navigate("/admin/lms/all-techTalk/add");
                  }}
                >
                  <div className="d-flex gap-2">
                    <div>
                      <VideoPlay />
                    </div>
                    <div className="m-0 drop-txt-wrapper">
                      <p className="m-0">Upload Tech Talk</p>
                      <p className="m-0">For New Tech Talks</p>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {userData?.decoded?.RoleName !== process.env.REACT_APP_USER_DES_USER && !location.pathname.includes("/admin/lms/") && !location.pathname.includes("/lms/") && (
            <div className="container border p-0 batch-wrapper hide-comp-wrapper">
              <div
                className="input-with-button"
                onClick={() => {
                  setBatchDropdown(!batchDropdown);
                }}
              >
                <input style={{ width: "8.5rem", outline: "none" }} type="text" className="custom-input" placeholder="Select Batch" value={Object.values(batches)} disabled />
                <div
                  className="modal-abs"
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    zIndex: 2,
                  }}
                  onClick={() => {
                    setBatchDropdown(!batchDropdown);
                  }}
                ></div>
                <ExpandMore />
              </div>
              <div
                className="ul-styling p-2"
                style={{
                  width: "10rem",
                  position: "absolute",
                  top: "3rem",
                  overflowY: "scroll",
                  display: batchDropdown ? "" : "none",
                }}
              >
                <BatchSelect allSelected={true} batchDataComingChild={batchDataComingFrmChild} setSelectedBatches={props.setSelectedBatches} selectedBatches={props.selectedBatches} selectAllChecked={selectAllChecked} setSelectAllChecked={setSelectAllChecked} />
              </div>
            </div>
          )}
          {userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_USER && !location.pathname.includes("/lms/") && (
            <div className="hide-comp-wrapper">
              <MentorAssignedAlerts />
            </div>
          )}
          <Dropdown className="d-flex margin hide-comp-wrapper">
            <Dropdown.Toggle id="dropdown-basic-for-header">
              {
                profilePic ?
                  (
                    <img src={profilePic} alt="profile-picture" loading="lazy" />
                  )
                  : (
                    <>
                      {userData?.decoded?.FirstName?.toUpperCase().slice(0, 1)}
                      {userData?.decoded?.LastName?.toUpperCase().slice(0, 1)}
                    </>
                  )
              }

            </Dropdown.Toggle>
            <Dropdown.Menu id="profileDropDown">
              <Dropdown.Item className="username">
                <span className="username">
                  {userData?.decoded?.FirstName} {userData?.decoded?.LastName} <br />
                </span>
                {
                  (userData?.decoded?.HrmsDesignationName.toLowerCase() == "intern" || userData?.decoded?.HrmsDesignationName.toLowerCase() == "sde") && (
                    <span className="deployed-status">{userData?.decoded?.RoleName === process.env.REACT_APP_USER_DES_USER ? (userData?.decoded?.IsDeployed.toLowerCase() === "true" ? "Occupied" : "On Bench") : ""}</span>
                  )
                }
              </Dropdown.Item>
              <Dropdown.Item>
                <span className="email">{userData?.decoded?.Email}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={handleLogout} className="logout-hover">
                <span className="logout">
                  <span className="pe-1">
                    <SignOut />
                  </span>{" "}
                  Logout
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </nav>
      </div>
    </>
  );
};

export default Header;
