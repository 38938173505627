import { React, ReportCertificate, FormatDate, ReportCertificateEmpty, CertificateSkeleton,TotalCourses} from "./../../../../UploadCourses/UploadCoursesImport";
import "./../Certification/Certification.css";

const Certification = (props) => {
  const { certificateData, certLoadingState } = props;

  return (
    <>
      <div className=" mt-4 ms-3 certificate-container d-flex justify-content-between">
        <div className="d-flex align-item-center mt-1">
          <ReportCertificate />
          <span className="ms-2 certificate-heading">All Certificates</span>
        </div>
        <div className="certification-count">{certificateData?.certificateCount !== undefined ? certificateData.certificateCount : 0}</div>
      </div>
      <div className="scroll mt-2" style={{ height: "12rem" }}>
        {certLoadingState ? (
          <>
            <CertificateSkeleton />
            <CertificateSkeleton />
          </>
        ) : certificateData?.certificates?.length == 0 || certificateData == null || certificateData.certificates == undefined || certificateData.certificateCount == undefined ? (
          <>
            <div className="d-flex justify-content-center align-items-center flex-column h-100">
              <div>
                <ReportCertificateEmpty />
              </div>
              <span className="mt-1">No certification has been achieved yet</span>
            </div>
          </>
        ) : (
          <>
            {certificateData?.certificates?.map(
              (val, ind) =>
                val.typeName === "Certificate" && (
                  <div key={ind}>
                    <div className="ms-3 me-4 border-bottom">
                      <div className="d-flex gap-1 ms-3 mt-2 align-item-center">
                      {val.subCategoryImage!=null && val.subCategoryImage!= "" ? <img src={`data:image/svg+xml;base64,${val.subCategoryImage}`} alt={val.subCategoryName} width={20} height={20}/> : <div><TotalCourses /></div>}
                        <div className="sub-name mt-1">{val.subCategoryName}</div>
                      </div>
                      <div className="ms-3 mt-1 certification-name">{val?.certificateName}</div>
                      <div className="d-flex gap-1">
                        <span className="issued ms-3 mt-1">Issued On: </span>
                        <div className="issue-date mt-1">{FormatDate(val?.issuedDate)}</div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Certification;
