import "./CourseCard.css";
import { React, useNavigate, FormatDate, Clock, Calendar, ContentIcon, View, AdditionalInfoUsersIcon } from "../../UserImport";
import { ProgressBar, SuccessfullVector } from "../../../AdminPortal/UploadCourses/UploadCoursesImport";

const CourseCard = ({ courseCardProp }) => {
  const {
    courseId,
    courseType,
    activityType,
    courseImageLink,
    activityLevel,
    activityGradeType,
    activityInstructor,
    activityEnrolledOrViews,
    activityName,
    activityDescription,
    activityCompleteCount,
    activityTotalCount,
    activityCourseStatus,
    activityModuleCount,
    activityDuration,
    activityStartDate,
    isDouble = false,
    activityResume = () => {},
    activityPlay = () => {},
    isActivityEnrolled = false,
    activityState,
    IsAssignmentReviewed,
    IsAssignmentSubmitted
  } = courseCardProp;
  const navigate = useNavigate();
  const truncateDescription = (description, maxLength) => {
    if (description?.length > maxLength) {
      return description.substring(0, maxLength) + "...";
    }
    return description;
  };
  return (
    <div className="main-container mt-2">
      <div className="card-main-container">
        <div className={`first-container ${isDouble ? "first-container-min-height" : ""}`}>
          <div className="first-left">
            <img src={courseImageLink} alt="tech-img" width={144} height={144} className="first-left-img" />
          </div>
          <div className="first-right">
            <div className="d-flex justify-content-between position-relative">
              <div className="d-flex align-items-center gap-1 ms-1">
                {courseType && (
                  <>
                    <div className="card-text-format">{activityLevel}</div>
                    <div className="card-det-dot" />
                    <div className="card-text-format">{activityGradeType}</div>
                    <div className="card-det-dot" />
                  </>
                )}
                <div className="card-text-format">{activityInstructor}</div>
              </div>
              {!courseType && (
                <div className="d-flex gap-1 align-items-center justify-content-center views">
                  <View className="eye-view-course-card" />
                  <div className="view-text">{activityEnrolledOrViews} Views</div>
                </div>
              )}
              {courseType && activityType === "Enrolled" && (
                <div className="d-flex gap-1 align-items-center justify-content-center views">
                  <AdditionalInfoUsersIcon className="course-card-enrolled-user" />
                  <div className="view-text">{activityEnrolledOrViews} Enrolled</div>
                </div>
              )}
            </div>
            <div className="d-grid gap-1">
              <div className=" w-100 ms-1">
                <div className="lms-enrolled-card-heading" data-bs-toggle="tooltip" data-bs-placement="top" title={activityName}>
                  {truncateDescription(activityName, 60)}
                </div>
              </div>
              <div>
                <div className="card-description">
                  <div>{truncateDescription(activityDescription, 140)}</div>
                </div>
              </div>
              <div className="lms-enroll-progress-bar">
                {courseType && (activityType === "Resume" || activityType === "Completed" || activityType === "Play" || isActivityEnrolled) ? (
                  <ProgressBar completedCount={activityCompleteCount} totalCount={activityTotalCount} courseStatus={activityCourseStatus} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="second-container">
          <div className="d-flex align-items-center justify-content-center gap-3">
            <div className="d-flex align-items-center justify-content-center gap-1">
              {activityModuleCount && (
                <>
                  <ContentIcon />
                  <div className="card-lower-text">{activityModuleCount} Modules</div>
                </>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center gap-1">
              <Clock />
              <div className="card-lower-text">
                {activityDuration?.replace(/^\s*(?:(\d+)\s*days?)?\s*(\d+)\s*(?:hrs?|hours?)\s*(\d+)?\s*(?:mins?|minutes?)?\s*$/i, (_, d = 0, h = 0, m = 0) => {
                  const totalHours = d ? Number(d) * 24 + Number(h) : Number(h);
                  return `${totalHours} hr(s) ${m} min(s)`;
                })}
              </div>
            </div>
            {activityStartDate && (
              <div className="d-flex align-items-center justify-content-center gap-1">
                <Calendar />
                <div className="card-lower-text">{FormatDate(activityStartDate)}</div>
              </div>
            )}
          </div>
          {courseType && activityType === "Resume" && (
            <>
            <div className="tooltip-course-card-container">
              <button className="btn course-card-csv-save-btn" disabled={!activityState} onClick={activityResume}>
                Resume
              </button>
              {!activityState && <span className="tooltip-course-card-text">Course is under maintainance</span>}
              </div>
            </>
          )}
          {courseType && activityType === "Play" && (
            <>
            <div className="tooltip-course-card-container">
              <button className="btn course-card-csv-tech-btn" disabled={!activityState} onClick={activityPlay}>
                Play
              </button>
              {!activityState && <span className="tooltip-course-card-text">Course is under maintainance</span>}
              </div>
            </>
          )}
          {!courseType && activityType === "Play" && (
            <>
              <button className="btn course-card-csv-tech-btn" onClick={activityPlay}>
                Play
              </button>
            </>
          )}
          {courseType && activityType === "Enrolled" && !isActivityEnrolled && (
            <>
              <button
                className="btn course-card-csv-tech-btn"
                onClick={() => {
                  sessionStorage.setItem("courseId", courseId);
                  navigate("/lms/course-detail");
                }}
              >
                Enroll
              </button>
            </>
          )}
          {courseType && activityType === "GoToCourse" && !isActivityEnrolled && (
            <>
              <p className="already-enrolled">
                <SuccessfullVector className="lms-notification-enrolled-vector" /> Enrolled
              </p>
            </>
          )}
          {courseType && activityType === "Enrolled" && isActivityEnrolled && (
            <>
            <div className="tooltip-course-card-container">
              <button className="btn course-card-csv-tech-btn" disabled={!activityState} onClick={activityPlay}>
                Play
              </button>
              {!activityState && <span className="tooltip-course-card-text">Course is under maintainance</span>}
              </div>
            </>
          )}
          {courseType && activityType === "Completed" && (
            <>
              <div className="tooltip-course-card-container">
                <button className="btn course-card-csv-tech-btn" disabled={!activityState} onClick={activityResume}>
                  Start Over
                </button>
                {!activityState && <span className="tooltip-course-card-text">Course is under maintainance</span>}
              </div>
            </>
          )}
        </div>
        {courseType ? (activityGradeType === "Instructor Grade" && IsAssignmentSubmitted && !IsAssignmentReviewed ? <div className="text-danger">Waiting for the instructor's grading to mark the course as complete</div> : "") : ""}
      </div>
    </div>
  );
};

export default CourseCard;
