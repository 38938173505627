import { useEffect, useState,AlertInfo,CustomModal,ExpandMore, TechActionType, EmptyThumbnail, CategoryDropdown, UploadCsvv, Validation } from "../../UploadCourses/UploadCoursesImport";
import TechDropdown from "../../Task/AssignTask/TechDropdown";

const TechTalkInfo = (props) => {
  const { uploadData, setUploadData, state, dispatch } = props;
  const [ratingData,setRatingData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames).length !== 0;
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({
        type: TechActionType.SET_TECH_VALUE,
        payload: techValueString,
      });
    }
    else{
      dispatch({
        type: TechActionType.SET_TECH_VALUE,
        payload: "",
      });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        var containsLetter = /[a-zA-Z0-9]/.test(value);
        if (!Validation(value, /^(?!.*(?:,,|\.\.|\-\-|\s{2}))[a-zA-Z0-9 ,.\-]{1,255}$/) || !containsLetter) {
          setUploadData((prevData) => ({
            ...prevData,
            name: value,
            isNextAllowed: true,
            error: {
              ...prevData.error,
              name: true
            }
          }));
        }
        else {
          setUploadData((prevData) => ({
            ...prevData,
            name: value,
            isNextAllowed: true,
            error: {
              ...prevData.error,
              name: false
            }
          }));
        }
        break;
      case "description":
        var containsLetter = /[a-zA-Z0-9]/.test(value);
        if (!Validation(value, /^(?!.*(?:,,|\.\.|\-\-|\s{2}))[a-zA-Z0-9 ,.\-]{1,2000}$/) || !containsLetter) {
          setUploadData((prevData) => ({
            ...prevData,
            description: value,
            isNextAllowed: true,
            error: {
              ...prevData.error,
              description: true
            }
          }));
        }
        else {
          setUploadData((prevData) => ({
            ...prevData,
            description: value,
            isNextAllowed: true,
            error: {
              ...prevData.error,
              description: false
            }
          }));
        }
        break;
    }
  };

  const handleDropdownToggle = (e) => {
    setUploadData((prevData) => ({
      ...prevData,
      dropdown: !prevData.dropdown,
    }));
  };

  const handleThumbnailUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validExtensions = ["jpg", "jpeg", "png"];
      const extension = file.name.split(".").pop().toLowerCase();

      if (validExtensions.indexOf(extension) === -1) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Please select JPG, JPEG, or PNG files only",
          icon: <AlertInfo/>,
        }));
        return;
      }
      const fileNameWithoutSpecialChars = file.name.replace(/[^a-zA-Z0-9.]/g, "");
      const trimmedFileName = fileNameWithoutSpecialChars.replace(/\s+/g, "");

      const thumbFile = new File([file], trimmedFileName, { type: file.type });
      setUploadData((prevData) => ({
        ...prevData,
        thumbnailFile: thumbFile,
        isThumbNailChanged: true
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        const inputThumb = reader.result;
        setUploadData((prevData) => ({
          ...prevData,
          thumbnail: inputThumb,
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <div className="mb-3">
        <label htmlFor="name" className="col-form-label form-title-names">
          Tech Talk Title<span className="text-danger">*</span>
        </label>
        <input type="text" className="form-control" id="name" name="name" placeholder="Enter Tech Talk Title" onChange={handleChange} value={uploadData.name} />
        {uploadData.name && uploadData.error.name && (
          <span className="text-danger error-msg">Only alphabets, colon, hyphen and comma allowed ranging between 1 to 255 characters</span>
        )}
      </div>
      <CategoryDropdown
        selectedCategory={uploadData}
        setSelectedCategory={setUploadData}
        categoryLabel={"Tech Talk"}
        subCategoryLabel={"Tech Talk"}
        categoryEnabled={true}
        categoryFilter={"Tech Talk"}
        subCategoryEnabled={true} />

      <div className="mb-3">
        <label htmlFor="name" className="col-form-label form-title-names">
          Tech Talk Description<span className="text-danger">*</span>
        </label>
        <textarea type="text" className="form-control" id="name" name="description" placeholder="Enter Tech Talk Description" onChange={(e) => handleChange(e)} value={uploadData.description} />
        {uploadData.description && uploadData.error.description && (
          <span className="text-danger error-msg">Only alphabets, colon, hyphen and comma allowed ranging between 1 to 2000 characters</span>
        )}
      </div>

      <div className="mb-3">
        <label for="technology-tag" className="col-form-label form-title-names" htmlFor="technology-tag">
          Tech Talk Tags<span className="text-danger">*</span>
          {state.selectedTechIds && state.selectedTechIds.length > 10 && (
          <span className="text-danger error-msg ms-2">(Maximum 10 technologies allowed)</span>
        )}
        </label>
        <div className="border p-0">
          <div className="selector-with-button" onClick={handleDropdownToggle}>
            <input type="text" className="selector-input" placeholder="Select Tech Talk Tags" value={state.techValue} disabled />
            <div className="dropdown-clickable"></div>
            <ExpandMore className="me-2" />
          </div>
        </div>
        <div className="position-relative">
          <div className={`tag-styling w-100 ${uploadData.dropdown ?"d-block": "d-none "}`}>
            <TechDropdown dispatch={dispatch}
              techDataComingChild={handleTechChange}
              selectedTechIdsfromChild={handleTechIdChange}
              selectedTechIds={state.selectedTechIds}
              technologyNames={state.selectedTechNames}
              selectedTech={""} />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="col-form-label form-title-names">
          Tech Talk Thumbnail<span className="text-danger">*</span>
        </label>
        <div className="d-flex align-items-center">
          <div>
            {uploadData?.thumbnail ? (
              <img className="course-thumbnail-img" src={uploadData.thumbnail} alt="Course Thumbnail" />
            ) : (
              <div>
                <EmptyThumbnail />
              </div>
            )}
          </div>
          <div className="mx-2">
            <p className="mb-1 thumbnail-text">Size: 700*430 Pixels</p>
            <p className="mb-1 thumbnail-text">File Supported: JPG, JPEG and PNG</p>
            <label htmlFor="thumbnail" className="upload-thumbnail-button pointer">
              <UploadCsvv />
              Upload Image
            </label>
            <input type="file" accept="image/*" className="form-control d-none" id="thumbnail" onChange={handleThumbnailUpload} />
          </div>
        </div>
      </div>
      {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
    </>
  );
};

export default TechTalkInfo;
