import "./TechTalkReport.css";
import { React, FormatDate, TechTalkEmptySate,TechTalkReportSkeleton } from "./../../../../UploadCourses/UploadCoursesImport";

const TechTalkReport = (props) => {
  const { TechTalkData, loadingState } = props;
  return (
    <>
      {" "}
      <div className="">
        <div>
          <table className="table">
            <thead>
              <tr>
                <th className="col tech-user-table-head tech-title-col head-text">Name</th>
                <th className="col tech-user-table-head tech-sub-col head-text">Subcategory</th>
                <th className="col tech-user-table-head tech-startDate-col text-center head-text">Start Date</th>
                <th className="col tech-user-table-head tech-duration-col text-center head-text">Duration</th>
                <th className="col tech-user-table-head tech-inst-col text-center head-text">Instructor</th>
              </tr>
            </thead>
            <tbody>
              {loadingState ? (
                <>
                  <TechTalkReportSkeleton />
                  <TechTalkReportSkeleton />
                  <TechTalkReportSkeleton />
                </>
              ) : (
                <>
                  {TechTalkData && TechTalkData.length > 0 ? (
                    <>
                      {" "}
                      {TechTalkData?.map((val, ind) => {
                        return (
                          <tr className="tech-table-tr" key={ind}>
                            <td className={`tech-heigh-vert ${ind % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"}`}>
                              <div className="tech-name-column">
                                <div className="course-tech-tags justify-content-center">
                                  <div className="title-head">{val.title}</div>
                                </div>
                              </div>
                            </td>
                            <td className={`tag2 tag2-adjustment tech-height-vertical ${ind % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"}`}>
                              <div>
                                <div>{val.subCategoryName}</div>
                              </div>
                            </td>
                            <td className={`tag2 text-center tag2-adjustment tech-heigh-vert ${ind % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"}`}>{FormatDate(val.startDate)}</td>
                            <td className={`tag2 text-center tag2-adjustment tech-heigh-vert ${ind % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"}`}>{val.totalVideosLength?.replace(/^\s*(?:(\d+)\s*days?)?\s*(\d+)\s*(?:hrs?|hours?)\s*(\d+)?\s*(?:mins?|minutes?)?\s*$/i,(_, d = 0, h = 0, m = 0) => {const totalHours = d ? Number(d) * 24 + Number(h) : Number(h);return `${totalHours} hr(s) ${m} min(s)`})}</td>
                            <td className={`tag2 text-center tag2-adjustment  tech-heigh-vert ${ind % 2 === 0 ? "enrolled-striped-even-row" : "enrolled-striped-odd-row"}`}>
                              <div>{`${val.firstName} ${val.lastName}`}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <tr className="tech-table-tr">
                        <td colSpan="5" className="text-center">
                          <TechTalkEmptySate width={125} height={125} />
                          <div>No tech talks available.</div>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default TechTalkReport;
