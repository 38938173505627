
import "./Task.css";
import { useState, AssignTask, MentorList, Header, SideNav ,Info} from "../AdminImport"

const Task = () => {
  const [selectedBatches, setSelectedBatches] = useState(JSON.parse(sessionStorage.getItem("Batches")) || []);
  return (
    <>
      <div  style={{ marginBottom: "5rem" }}>
        <Header
          selectedBatches={selectedBatches}
          setSelectedBatches={setSelectedBatches}
        />
        <SideNav />
      </div>

      <div className="assign-task-page">
      <h3 className="milestone-header ms-5">Assign Mentor Role</h3>
      <div className="alert alert-info mb-0 box-for-alert-message mt-2 ms-5" role="alert">
        <div className="d-flex justify-content-center align-items-center">
          <Info />
        </div>
        <div className="text-for-alert">
          <p className="m-0 mx-2">Instructor role if assigned to user from this page will apply to the assigned users through out the entire system, including dashboard and courses</p>
        </div>
      </div>
        <div className="container-fluid ">
          <div className="row mt-4">
            {/* <div className=" col-md-7 p-0" style={{ marginLeft: "56px" }}>
              <AssignTask selectedBatches={selectedBatches} />
            </div> */}
            <div className=" col-md-4 p-0 " style={{ marginLeft: "16px" }}>
              <MentorList selectedBatches={selectedBatches} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Task;
