import "./CourseInfo.css";
import TechDropdown from "../../Task/AssignTask/TechDropdown";
import {CategoryDropdown,TechActionType, axios, handleApiError, useNavigate, decryptData,useState, useEffect, UploadCsvv, ExpandMore, EmptyThumbnail,CustomModal,AlertInfo} from "../UploadCoursesImport";
const CourseInfo = (props) => {
  const { courseUploadData, setCourseUploadData, state, dispatch,error,setError}=props;
  const [dropDown, setDropDown] = useState(false);
  const [level, setLevel] = useState([]);
const [ratingData,setRatingData] = useState({
    isModalOpen: false,
    modalType: "",
    modalTitle: "",
    modalMessage: "",
    icon: null,
  });
  const navigate = useNavigate();

  const handleClose = () => {
    setRatingData((prevData) => ({
      ...prevData,
      isModalOpen: false,
      modalType: "",
      modalTitle: "",
      modalMessage: "",
      icon: null,
    }));
  };

  useEffect(() => {
    const isTechEmpty = Object.keys(state.selectedTechNames).length !== 0;
    if (isTechEmpty) {
      const techValueString = Object.values(state.selectedTechNames).join(", ");
      dispatch({
        type: TechActionType.SET_TECH_VALUE,
        payload: techValueString,
      });
    }
    else{
      dispatch({
        type: TechActionType.SET_TECH_VALUE,
        payload: "",
      });
    }
  }, [state.selectedTechNames, state.selectedTechIds, state.closeModal]);


  
  let parsedObject;
  const data = localStorage.getItem("userData");
  data
  ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY))
  : navigate("/error/statusCode=400");
  const fetchLevels = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setLevel(response.data.result);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };
  
  useEffect(() => {
    fetchLevels();
  }, []);

  const handleTechChange = (techData) => {
    dispatch({ type: TechActionType.SET_TECH_NAME, payload: techData });
  };
  const handleTechIdChange = (techIdData) => {
    dispatch({ type: TechActionType.SET_TECH_ID, payload: techIdData });
  };

  const handleDropdownToggle = () => {
    setDropDown(!dropDown);
  };

  const handleOptionChange = (event) => {
    setCourseUploadData((prevData) => ({
      ...prevData,
      level: event.target.name,
      levelId: event.target.value,
    }));
  };
  const handleChangeName = (e) => {
    const inputName = e.target.value;
    const specialCharsRegex = /^(?!.*(?:,,|\.\.|\-\-|\s{2}))(?!.*:{2})[a-zA-Z0-9 .:-]{0,255}$/;

    const containsLetter = /[a-zA-Z0-9]/.test(inputName);
    if (!specialCharsRegex.test(inputName) || !containsLetter) {
        setError((prevData) => ({
            ...prevData,
            nameError: "Only alphabets, colon, hyphen and comma allowed ranging between 1 to 255 characters",
        }));
    } else {
        setError((prevData) => ({
            ...prevData,
            nameError: "",
        }));
    }

    setCourseUploadData((prevData) => ({
        ...prevData,
        name: inputName,
    }));
};

  const handleDescription = (e) => {
    const inputDesc = e.target.value;
    const specialCharsRegex = /^(?!.*(?:,,|\-\-|\.\.|\s{2}))(?!.*:{2})[a-zA-Z0-9 ,.\-:]{1,2000}$/
    
    const containsLetter = /[a-zA-Z0-9]/.test(inputDesc);
    if (!specialCharsRegex.test(inputDesc) || !containsLetter) {
      setError((prevData) => ({
        ...prevData,
        descError: "Only alphabets, colon, hyphen and comma allowed ranging between 1 to 2000 characters",
      }));
    }
     else {
      setError((prevData) => ({
        ...prevData,
        descError: "",
      }));    }
    setCourseUploadData((prevData) => ({
      ...prevData,
      description: inputDesc,
    }));
  };

  const handleThumbnailUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const validExtensions = ["jpg", "jpeg", "png"];
      const extension = file.name.split(".").pop().toLowerCase();

      if (validExtensions.indexOf(extension) === -1) {
        setRatingData((prevData) => ({
          ...prevData,
          isModalOpen: true,
          modalType: "error",
          modalTitle: "Error !",
          modalMessage: "Please select JPG, JPEG, or PNG files only",
          icon: <AlertInfo/>,
        }));
        return;
      }
      const fileNameWithoutSpecialChars = file.name.replace(/[^a-zA-Z0-9.]/g, '');
      const trimmedFileName = fileNameWithoutSpecialChars.replace(/\s+/g, '');

    const thumbFile = new File([file], trimmedFileName, { type: file.type });
    setCourseUploadData((prevData) => ({
      ...prevData,
      thumbnailFile: thumbFile,
      IsThumbNailChanged:true
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      const inputThumb = reader.result;
      setCourseUploadData((prevData) => ({
        ...prevData,
        thumbnail: inputThumb,
      }));
    };
    reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="mb-3">
        <label htmlFor="name" className="col-form-label form-title-names">
          Course Title<span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Enter Course Title"
          onChange={(e) => handleChangeName(e)}
          value={courseUploadData.name}
        />
        {courseUploadData.name && error?.nameError!=="" && (
          <span className="text-danger error-msg">{error?.nameError}</span>
        )}
      </div>
      <CategoryDropdown selectedCategory={courseUploadData} setSelectedCategory={setCourseUploadData} categoryLabel="Course" subCategoryLabel="Course" categoryEnabled={true} subCategoryEnabled={true} categoryFilter={null}/>
      <div className="mb-3">
        <label htmlFor="name" className="col-form-label form-title-names">
          Course Descripton<span className="text-danger">*</span>
        </label>
        <textarea
          type="text"
          className="form-control"
          id="name"
          placeholder="Enter Course Description"
          onChange={(e) => handleDescription(e)}
          value={courseUploadData.description}
        />
        {courseUploadData.description && error?.descError!=="" && (
          <span className="text-danger error-msg">{error?.descError}</span>
        )}
      </div>
      <div className="mb-3">
        <span className="d-flex mb-2 form-title-names">
          Course Level<span className="text-danger">*</span>
        </span>
        <div className="d-flex">
          <div className="form-check course-level-label">
            {level?.map((level) => (
              <label key={level.id} className="beginner-form-label">
                <input
                  className="form-check-input color-of-radio flexRadioDefault1"
                  type="radio"
                  name={level.name}
                  value={level.id}
                  onChange={(e) => handleOptionChange(e)}
                  checked={courseUploadData.levelId === level.id}
                />
                {level.name==="Advance"?"Advanced":level.name}
              </label>
            ))}
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label
          for="technology-tag"
          className="col-form-label form-title-names"
          htmlFor="technology-tag"
        >
          Course Tags<span className="text-danger">*</span>
          {state.selectedTechNames && state.selectedTechNames.length > 10 && <span style={{ color: "red", fontSize: "12px", marginLeft: "10px"}}>(Maximum 10 technologies allowed)</span>}
        </label>
        <div className="border p-0">
          <div className="selector-with-button" onClick={handleDropdownToggle}>
            <input
              type="text"
              className="selector-input"
              placeholder="Select Course Tags"
              value={state.techValue}
              disabled
            />
            <div
              className="dropdown-clickable"
              onClick={() => {
                handleDropdownToggle();
              }}
            ></div>
            <ExpandMore className="me-2"/>
          </div>
        </div>
        <div className="position-relative">
          <div
            style={{ display: dropDown ? "" : "none" }}
            className="tag-styling w-100"
          >
            <TechDropdown
              dispatch={dispatch}
              techDataComingChild={handleTechChange}
              selectedTechIdsfromChild={handleTechIdChange}
              selectedTechIds={state.selectedTechIds}
              technologyNames={state.selectedTechNames}
              selectedTech={""}
            />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label className="col-form-label form-title-names">
          Course Thumbnail<span className="text-danger">*</span>
        </label>
        <div className="d-flex align-items-center">
          <div>
            {courseUploadData?.thumbnail ? (
              <img
                className="course-thumbnail-img"
                src={courseUploadData.thumbnail}
                alt="Course Thumbnail"
              />
            ) : (
              <div>
                <EmptyThumbnail />
              </div>
            )}
          </div>
          <div className="mx-2">
            <p className="mb-1 thumbnail-text">Size: 700*430 Pixels</p>
            <p className="mb-1 thumbnail-text">File Supported: JPG, JPEG and PNG</p>
            <label htmlFor="thumbnail" className="upload-thumbnail-button pointer">
              <UploadCsvv />
              Upload Image
            </label>
            <input
              type="file"
              accept="image/*"
              className="form-control d-none"
              id="thumbnail"
              onChange={handleThumbnailUpload}
            />
          </div>
        </div>
        {ratingData.isModalOpen && (
        <CustomModal
          modalProps={{
            icon: ratingData.icon,
            show: ratingData.isModalOpen,
            type: ratingData.modalType,
            title: ratingData.modalTitle,
            message: ratingData.modalMessage,
            onCancel: handleClose,
          }}
        />
      )}
      </div>
    </>
  );
};

export default CourseInfo;
