import "./ViewAllCourses.css";
import { useEffect, useState, NoCourses, decryptData, useNavigate, axios, handleApiError, FormatDate, CourseCard, Skeleton, CourseCardSkeleton, EnrolledCourseEmptyState, ArrowForwardIcon, Filter } from "../../UserImport";

const ViewAllCourses = ({ viewAllProp }) => {
  const [data, setData] = useState({
    data: "Ongoing",
    userEnrolled: true,
  });
  const { title, resumeData, isCourse, loadingState, componentType, totalCoursesState, setTotalCoursesState, filterState, setFilterState } = viewAllProp;
  const [resumeFilter, setResumeFilter] = useState([]);


  var filterCoursesByStatus;
  const navigate = useNavigate();

  if (componentType === "Resume") {
    filterCoursesByStatus = (courses, status) => {
      if (status?.toLowerCase() === "ongoing") {
        return courses?.filter((course) => course?.courseStatus?.toLowerCase() === "ongoing" || course?.courseStatus?.toLowerCase() === "enrolled");
      } else {
        return courses?.filter((course) => course?.courseStatus?.toLowerCase() === status?.toLowerCase());
      }
    };
  }
  useEffect(() => {
    if (componentType === "Resume") {
      setResumeFilter(filterCoursesByStatus(resumeData, data.data)?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)));
    } else setResumeFilter(resumeData)?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  }, [data.data, resumeData, componentType]);

  const fetchLevel = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Skill/getSkillsLevel?isCourseSkillLevel=true`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setTotalCoursesState((prevState) => ({
          ...prevState,
          apiLevel: response.data.result,
          apiGrade: prevState.apiGrade,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };

  const fetchGrade = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/getGradeType`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };

    try {
      const response = await axios.get(url, { headers });
      if (response.status === 200) {
        setTotalCoursesState((prevState) => ({
          ...prevState,
          apiGrade: response.data.result,
          apiLevel: prevState.apiLevel,
        }));
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const errorResp = error.response ? error.response : null;
      handleApiError(errorResp, navigate);
    }
  };
  useEffect(() => {
    if (totalCoursesState !== undefined) {
      fetchLevel();
      fetchGrade();
    }
  }, []);


  let parsedObject;
  const usersdata = localStorage.getItem("userData");
  usersdata ? (parsedObject = decryptData(usersdata, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");
  var userId = parsedObject["decoded"]["Id"];

  const ResumeCourse = async (courseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/resumeCourse?userId=${userId}&courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", courseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const StartCourse = async (storeCourseId) => {
    var userId = parsedObject["decoded"]["Id"];
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/Course/startCourse?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const formData = new FormData();
    formData.append("courseId", storeCourseId);
    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 201) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", storeCourseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const StartTechTalk = async (storeCourseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/startTechTalk?userId=${userId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const formData = new FormData();
    formData.append("techTalkId", storeCourseId);
    try {
      const response = await axios.post(url, formData, { headers });
      if (response.data.success === 201) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", storeCourseId);
        navigate("/lms/techtalk");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const ResumeTechTalk = async (courseId) => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/TechTalk/resumeTechTalk?userId=${userId}&courseId=${courseId}`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data.success === 200) {
        sessionStorage.setItem("_historyId", response.data.result);
        sessionStorage.setItem("_activityId", courseId);
        navigate("/lms/course");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };



  return (
    <div className="course-main-container ms-3">
      <div className="course-header w-100">
        {loadingState ? (
          <Skeleton className="ms-2" width={190} height={20} baseColor="#28519E80" />
        ) : (
          <>
            <div className="course-heading">{title}</div>
          </>
        )}
      </div>
      <div className="d-flex justify-content-between mt-2 ms-3">
        {componentType === "Resume" && (
          <div className="text-result mt-1">
            {loadingState ? (
              <Skeleton width={190} height={20} />
            ) : (
              resumeFilter === undefined ? <>0 results for “{data.data} course”</> :
                <>
                  {resumeFilter?.length} results for “{data.data} course”
                </>
            )}
          </div>
        )}
        {(componentType === "Play" || componentType === "Enrolled") && (
          <div className="text-result mt-1">
            {loadingState ? (
              <Skeleton width={190} height={20} />
            ) : (
              resumeFilter === undefined ? <div>0 results for “ {title} "</div> :
                <div>
                  {resumeFilter?.length} results for “ {title} "
                </div>
            )}
          </div>
        )}
        {componentType === "Enrolled" && (
          <div className="total-uploaded-courses-inner-filter mx-4">
            <button
              className="fw-bold filter-button"
              onClick={() => {
                setFilterState({
                  ...filterState,
                  showFilterBase: true,
                });
              }}
            >
              <Filter className="blue-filter-icon" /> Filter
            </button>
          </div>
        )}
        {componentType === "Resume" && (
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <div className="progress-filter-div d-flex justify-content-center">
              <button
                className={`d-flex align-items-center justify-content-center w-50 border-0 ${data.data === "Ongoing" ? `complete-filter` : `ongoing-filter `}`}
                onClick={() => {
                  setData(() => ({
                    data: "Ongoing",
                    userEnrolled: true,
                  }));
                }}
              >
                <p className="text-field m-0">Ongoing</p>
              </button>
              <button
                className={`d-flex align-items-center justify-content-center w-50 border-0 ${data.data === "Completed" ? `complete-filter` : `ongoing-filter `}`}
                onClick={() => {
                  setData((prev) => ({
                    ...prev,
                    data: "Completed",
                  }));
                }}
              >
                <p className="text-field m-0">Completed</p>
              </button>
            </div>
          </div>
        )}
      </div>
      {isCourse && componentType === "Resume" && (
        <div className="card-container">
          {loadingState ? (
            <>
              {[...Array(2).keys()].map((index) => (
                <div className="course-row" key={index}>
                  {[...Array(2).keys()].map((innerIndex) => (
                    <div className="course-col" key={innerIndex}>
                      <CourseCardSkeleton />
                    </div>
                  ))}
                </div>
              ))}
            </>
          ) : (
            <>
              {resumeFilter && resumeFilter.length > 0 ? (
                <>
                  {resumeFilter.map((_, index) => (
                    <div className="course-row" key={index}>
                      {[...Array(2).keys()].map((innerIndex) => {
                        const dataIndex = index * 2 + innerIndex;
                        const currentData = resumeFilter[dataIndex];
                        return currentData ? (
                          <div className="course-col" key={innerIndex}>
                            <CourseCard
                              courseCardProp={{
                                courseId: currentData.id,
                                courseType: true,
                                activityType: (() => {
                                  if (currentData?.courseStatus === "Completed") {
                                    return "Completed";
                                  } else if (currentData?.courseStatus === "Enrolled") {
                                    return "Enrolled";
                                  } else {
                                    return "Resume";
                                  }
                                })(),
                                courseImageLink: currentData?.thumbnailUri,
                                activityLevel: currentData?.levelName,
                                activityGradeType: currentData?.gradeType,
                                activityInstructor: currentData?.instructorName,
                                activityName: currentData?.courseTitle,
                                activityDescription: currentData?.description,
                                activityCompleteCount: currentData?.completedModuleCount,
                                activityTotalCount: currentData?.moduleCount,
                                activityCourseStatus: currentData?.courseStatus,
                                activityModuleCount: currentData?.moduleCount,
                                activityDuration: currentData?.duration,
                                activityState: currentData?.courseState,
                                IsAssignmentReviewed: currentData?.isAssignmentReviewed,
                                IsAssignmentSubmitted: currentData?.isAssignmentSubmitted,
                                activityStartDate: FormatDate(currentData?.startDate),
                                isDouble: true,
                                activityResume: () => ResumeCourse(currentData?.id),
                                activityPlay: () => StartCourse(currentData?.id),
                                isActivityEnrolled: data.userEnrolled,
                              }}
                            />
                          </div>
                        ) : null;
                      })}
                    </div>
                  ))}
                </>
              ) : (
                <div className="view-all-empty-state">
                  <EnrolledCourseEmptyState />
                  <div className="empty-desc">{data.data === "Ongoing" ? "No ongoing courses!" : data.data === "Completed" ? "No completed courses!" : data.data === "Overdue" ? "No overdue courses!": null}</div>
                  <div
                    className="d-flex gap-2 pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/lms/dashboard");
                    }}
                  >
                    <div className="empty-nav">Go To Dashboard</div>
                    <ArrowForwardIcon />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {isCourse &&
        componentType === "Enrolled" && // This is for Enroll Ui rendering here is course is true and component type is "Enrolled"
        (loadingState ? (
          <>
            <div className="card-container">
              {[...Array(2).keys()].map((index) => (
                <div className="course-row" key={index}>
                  {[...Array(2).keys()].map((innerIndex) => (
                    <div className="course-col" key={innerIndex}>
                      <CourseCardSkeleton />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="card-container">
            {resumeData && resumeData?.length > 0 ? (
              <>
                {resumeData?.map((_, index) => (
                  <div className="course-row" key={index}>
                    {[...Array(2).keys()].map((innerIndex) => {
                      const dataIndex = index * 2 + innerIndex;
                      const currentData = resumeData[dataIndex];
                      return currentData ? (
                        <div className="course-col" key={innerIndex}>
                          <CourseCard
                            courseCardProp={{
                              courseId: currentData.id,
                              courseType: true,
                              activityType: currentData?.alreadyEnroll ? "GoToCourse" : "Enrolled",
                              courseImageLink: currentData?.thumbnailUri,
                              activityLevel: currentData?.levelName,
                              activityGradeType: currentData?.gradeType,
                              activityInstructor: currentData?.instructorList[0].firstName + currentData?.instructorList[0].lastName,
                              activityName: currentData?.title,
                              activityDescription: currentData?.description,
                              activityEnrolledOrViews: currentData?.userEnrolledCount,
                              activityState: currentData?.courseState,
                              IsAssignmentReviewed: currentData?.isAssignmentReviewed,
                              IsAssignmentSubmitted: currentData?.isAssignmentSubmitted,
                              // activityCompleteCount: currentData?.completedModuleCount,
                              // activityTotalCount: currentData?.moduleCount,
                              // activityCourseStatus: currentData?.courseStatus,
                              activityModuleCount: currentData?.moduleCount,
                              activityDuration: currentData?.courseCompletionTime,
                              activityStartDate: null,
                              isDouble: true,
                            }}
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                ))}
              </>
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center gap-2 w-100 empty-view-courses">
                <div>
                  <NoCourses />
                </div>
                <p className="fw-bold">No Courses Uploaded on This Topic </p>
              </div>
            )}
          </div>
        ))}
      {!isCourse &&
        componentType === "Play" && // this is for tech talk rendering here isCourseWil go false
        (loadingState ? (
          <>
            <div className="card-container">
              {[...Array(2).keys()].map((index) => (
                <div className="course-row" key={index}>
                  {[...Array(2).keys()].map((innerIndex) => (
                    <div className="course-col" key={innerIndex}>
                      <CourseCardSkeleton />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="card-container">
            {resumeFilter && resumeFilter.length > 0 ? (
              <>
                {resumeFilter.map((_, index) => (
                  <div className="course-row" key={index}>
                    {[...Array(2).keys()].map((innerIndex) => {
                      const dataIndex = index * 2 + innerIndex;
                      const currentData = resumeFilter[dataIndex];
                      return currentData ? (
                        <div className="course-col" key={innerIndex}>
                          <CourseCard
                            courseCardProp={{
                              courseType: false,
                              activityType: "Play",
                              courseImageLink: currentData?.thumbnailUri,
                              activityInstructor: currentData?.instructorName,
                              activityEnrolledOrViews: currentData?.viewsCount,
                              activityName: currentData?.title,
                              activityDescription: currentData?.description,
                              activityDuration: currentData?.totalVideosLength,
                              activityStartDate: currentData?.createdDate,
                              activityResume: () => ResumeTechTalk(currentData?.id),
                              activityPlay: () => StartTechTalk(currentData?.id),
                            }}
                          />
                        </div>
                      ) : null;
                    })}
                  </div>
                ))}
              </>
            ) :
              (
                <div className="d-flex justify-content-center flex-column align-items-center gap-2 w-100 empty-view-courses">
                  <div>
                    <NoCourses />
                  </div>
                  <p className="fw-bold">No Tech Talk Uploaded on This Topic </p>
                </div>
              )
            }
          </div>
        ))}
    </div>
  );
};

export default ViewAllCourses;
