import { useNavigate, decryptData, axios, handleApiError, html2canvas, SapphireLevelBelt, IndigoLevelBelt, InfinityLevelBelt, TurquoiseLevelBelt, AquamarineLevelBelt, MandarinLevelBelt, MarigoldLevelBelt, GraphiteLevelBelt, MoonstoneLevelBelt, IvoryLevelBelt } from "../../UserImport"
import "./MilestoneAchievementModal.css"

const MilestoneAchievementModal = (props) => {
  const { onModalClose, milestoneLevel, fullName, calledFromMilestone = false }  = props;

  const navigate = useNavigate();
  let parsedObject;
  const data = localStorage.getItem("userData");
  data ? (parsedObject = decryptData(data, process.env.REACT_APP_USER_KEY)) : navigate("/error/statusCode=400");

  const handleCross = async () => {
    const url = `${process.env.REACT_APP_API_URL_DOTNET}/UserMileStone/markMilestoneAchievementModalAsShown`;
    const headers = {
      Authorization: `Bearer ${parsedObject["token"]}`,
    };
    const body = {
      id: parseInt(parsedObject["decoded"].Id),
      levelName: milestoneLevel,
    };

    try {
      const response = await axios.post(url, body, { headers });
      if (response.status === 200) {
        onModalClose(true);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      const statusCode = error.response ? error.response : null;
      handleApiError(statusCode, navigate);
    }
  };

  const handleDownload = () => {
    const congoComponent = document.getElementById("congoComponent");
    congoComponent.classList.add("screenshot-capture");

    html2canvas(congoComponent, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${fullName}_${milestoneLevel} Belt.jpeg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      congoComponent.classList.remove("screenshot-capture");
    });
  };

  const renderBeltComponent = () => {
    switch (milestoneLevel) {
      case "Ivory":
        return <IvoryLevelBelt />;
      case "Moonstone":
        return <MoonstoneLevelBelt />;
      case "Graphite":
        return <GraphiteLevelBelt />;
      case "Marigold":
        return <MarigoldLevelBelt />;
      case "Mandarin":
        return <MandarinLevelBelt />;
      case "Aquamarine":
        return <AquamarineLevelBelt />;
      case "Turquoise":
        return <TurquoiseLevelBelt />;
      case "Indigo":
        return <IndigoLevelBelt />;
      case "Sapphire":
        return <SapphireLevelBelt />;
      case "Infinity":
        return <InfinityLevelBelt />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="milestone-modal-parent-congo-wrapper">
        <div id="congoComponent" className="milestone-modal-child-congo-wrapper">
          <div className="row milestone-modal-crossBtn">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                if(calledFromMilestone) {
                  onModalClose(true);
                } else {
                  handleCross()
                }
              }}
            ></button>
          </div>
          <div className="milestone-modal-center-content gap-4">
          <div className="d-grid gap-3">
            <div className="milestone-modal-heading">Congratulations!</div>
            <div className="milestone-modal-name-subheading">{fullName}</div>
            <div className="milestone-modal-info">You are {milestoneLevel} Belt holder now!</div>
          </div>
          <div className="mt-2">{renderBeltComponent()}</div>
          <div className="milestone-modal-belt-tag">{milestoneLevel} Belt</div>
          <div className="row milestone-modal-Ccontinue">
          <button
              type="button"
              className="milestone-modal-congo-view-result-btn"
              onClick={() => {
                if(calledFromMilestone) {
                  onModalClose(true);
                } else {
                  handleCross()
                }
              }}
              id="congocloseButton"
            >
              Close
            </button>
            <button
              type="button"
              className="milestone-modal-continueBtn"
              onClick={handleDownload}
              id="congodownloadButton"
            >
              Download
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MilestoneAchievementModal;